<template>    
    <transition name="fade">
    <MobileNav v-if="$store.state.isMobileNavOpen && validLogin" 
        class="md:hidden"
        :hasNotch="hasNotch"
        :style="customCSSVars"     
        :mainNav="mainNavLinks"
        :secondaryNav="customLinks"
        :additionalNav="serviceLinks"/>    
    </transition>   
	<div :class="`${$store.state.isBusy ? 'cursor-wait' : ''} ${bodyPadding} overflow-x-hidden md:overflow-x-auto`" :style="customCSSVars">
	    <transition name="fade" mode="in-out">
			<div v-if="$store.state.isBusy && !$store.state.hideBusyAnim && $store.state.initialLoadComplete" class="mt-24 absolute w-full">
				<img src="./assets/img/loading.gif" class="mx-auto w-12 h-12" />
			</div>
		</transition>
		<!-- Config error -->
		<div v-if="configError">
			<strong>{{configError}}</strong>
		</div>
		<!-- Loading anim or timeout display-->
		<div v-else-if="$store.state.isLoading" class="mt-24 absolute w-full"> 
            <transition name="fade" mode="in-out">
			    <img v-if="timerTime < timeoutWait || $store.state.initialLoadComplete" src="./assets/img/loading.gif" class="mx-auto w-12 h-12"/>     
            </transition>
            <!--Timeout 'Update Required/Timed Out' Display-->
            <transition name="fade" mode="in-out">
                <div v-if="timerTime >= timeoutWait && !$store.state.initialLoadComplete"
                    class="w-full px-3 flex flex-col items-center">

                    <!--Timed out display: Version # matches, connection issue-->
                    <div v-if="$store.state.updateStatus == 'downloading'"
                        :class="`bg-red-600 text-white flex flex-col w-full p-2 rounded items-center mx-2 cursor-pointer`">
                        <div class="flex flex-row my-2">
                            <Icon id="exclamation" class="h-8 w-8 mr-1" />
                            <span class="font-bold text-2xl uppercase">Connection Issue Detected</span>
                        </div>                        
                        <div class="flex flex-col w-5/6">
                            <div>There was an issue connecting to the application server, ensure that you have a working internet connection on your device.</div>
                            <div class="mt-5">If the issue persists please try again later.</div>
                        </div>
                    </div>

                    <!--Update Required Display: If version # doesnt match-->
                    <div v-else class="w-full flex flex-col items-center">
                        <div :class="`bg-green-600 text-white flex flex-col w-full p-2 rounded items-center mx-2 cursor-pointer`"
                            @click="openAppStore()">

                            <div class="flex flex-row my-2">
                                <Icon id="chev-double-up" class="h-8 w-8 mr-1" />
                                <span class="font-bold text-2xl uppercase">Update Required</span>
                            </div>

                            <div class="w-full p-2 rounded-sm bg-white">
                                <img v-if="appInfo.id == 'com.mnolb.mn3fr3'" src="./assets/img/logos/logo_mn3fr3.png" class="mx-auto w-3/4 rounded-sm" />
                                <img v-else-if="appInfo.id == 'com.mnolb.latvianmn'" src="./assets/img/logos/logo_latvianmn.png" class="mx-auto w-3/4 rounded-sm" />
                                <img v-else-if="appInfo.id == 'com.mnolb.spine'" src="./assets/img/logos/logo_spine.png" class="mx-auto w-3/4 rounded-sm" />
                                <img v-else-if="appInfo.id == 'com.mnolb.topmark'" src="./assets/img/logos/logo_topmark.png" class="mx-auto w-3/4 rounded-sm" />
                                <img v-else-if="appInfo.id == 'com.mnolb.tct'" src="./assets/img/logos/logo_tct.png" class="mx-auto w-3/4 rounded-sm" />          
                                <img v-else-if="appInfo.id == 'com.mnolb.ocu'" src="./assets/img/logos/logo_ocu.png" class="mx-auto w-3/4 rounded-sm" />          
                                <img v-else-if="appInfo.id == 'com.mnolb.tefcu'" src="./assets/img/logos/logo_tefcu.png" class="mx-auto w-3/4 rounded-sm" />          
                                <img v-else-if="appInfo.id == 'com.mnolb.cnfcu'" src="./assets/img/logos/logo_cnfcu.png" class="mx-auto w-3/4 rounded-sm" />          
                                <img v-else-if="appInfo.id == 'com.mnolb.cfcu'" src="./assets/img/logos/logo_cfcu.png" class="mx-auto w-3/4 rounded-sm" />          
                                <img v-else-if="appInfo.id == 'com.mnolb.smfcu'" src="./assets/img/logos/logo_smfcu.png" class="mx-auto w-3/4 rounded-sm" />                    
                            </div>    

                            <div class="flex flex-col w-5/6">
                                <img :src="$store.state.theme.mainLogoUrl" class="w-auto rounded-sm mx-auto max-w-xs mb-5" />
                                <span class="text-sm">A new version of the <b>{{appInfo.name}}</b> app is now available for download on the {{storeName}}!</span>

                                <div class="mt-5 w-full bg-gray-200 flex flex-row justify-start items-center p-2 rounded-sm text-gray-800">
                                        <img v-if="$store.state.isAndroid" src="./assets/img/playstore.png" class="mr-4 h-16 w-16" />
                                        <img v-else src="./assets/img/appstore.png" class="mr-4 h-16 w-16" />
                                    <div class="flex flex-col w-full justify-start items-left leading-5 mt-1">
                                        <div class="text-sm">Click here to open the {{storeName}} app and update</div>
                                        <div class="font-bold text-lg text-gray-900 leading-5">{{appName(appInfo.id)}}</div>
                                    </div>
                                </div>
                            </div> 
                            
                        </div>                    

                        <!--Manual Search Note-->
                        <div class="w-full bg-gray-300 leading-4 p-2 mt-5 text-sm flex flex-row items-center justify-start rounded-sm">
                            <Icon id="mobile" class="h-12 w-12 mr-2" />
                            <div>If the above button doesn't open the {{storeName}} on your device, please <b>open the {{storeName}}</b> and <b>search for '{{appName(appInfo.id)}}'</b></div>
                        </div>
                        
                        <!--Delete Old Note-->
                        <div class="w-full bg-gray-300 leading-4 p-2 mt-5 text-sm flex flex-row items-center justify-start rounded-sm">
                            <Icon id="exclamation" class="h-12 w-12 mr-2" />
                            <div>If you are still experiencing issues after updating, please uninstall and reinstall your application</div>
                        </div>
                    </div>
                    
                </div>
            </transition>
		</div>
		<!-- Login display -->
		<div v-else-if="!validLogin">
            <!--Site wide alert: Mobile-->        
            <Notice v-if="!$store.state.noticeClosed && hasSitewideAlert && !isAdminScreen"
                :showClose="true"
                @close="closeNotice()"
                :class="`${hasNotch ? 'pt-16' : ''} mt-0 fixed md:hidden`"
                :isVert="true"
                :title="$store.state.fi.alert.title"
                :notice="$store.state.fi.alert.alert"
                :url="$store.state.fi.alert.url"
                :level="$store.state.fi.alert.level" /> 

            <div class="mn3-body-bg text-gray-700 pt-16 md:pt-0" :style="`min-height: 90vh`">
				<TopBar v-if="!isAdminScreen" :class="` fixed hidden md:flex`" :isBlank="true" :hasNotch="hasNotch" @closeNotice="closeNotice()" />
				<BottomBar v-if="!keyboardShowing && isLoggedIn && !isAdminScreen" 
                    class="block md:hidden" 
                    :navLinks="navLinks" 
                    :isLoggedIn="false"  />
				<div :class="`${contentTopPadding} flex justify-between width-full border-0`">					
					<div id="screen-padding-left" class="flex-grow"></div>	
						<div class="flex flex-col w-full md:w-96">
							<div v-if="$store.state.showInactiveNotice" class="mn3-content p-3 rounded-sm m-5">
								<p>You have been logged out due to inactivity.</p>
							</div>			
							<div v-else-if="$store.state.showLogoutNotice" class="mn3-content p-3 rounded-sm m-5">
								<p>You have been logged out.</p>
							</div>				
							<div v-if="screenCategory == 'fiadmin'">
								<FinLogin />
							</div>
							<div v-else-if="screenCategory == 'appadmin'">
								<AppLogin />
							</div>
							<div v-else-if="screenCategory == 'membnet'">
								<MemLogin />
							</div>
						</div>
					<div id="screen-padding-right" class="flex-grow"></div>
				</div>
				<div class="spacer flex-grow"></div>
			</div>
		</div>
		<!-- Logged in screen display -->
		<div v-else>
            <!--Mobile screen title-->
			<div v-if="!isAdminScreen && !hasNotch && !this.$store.state.isMobileNavOpen " 
            :class="`w-full fixed text-xl text-center font-bold mn3-navbar flex items-center justify-center shadow md:hidden z-50 ${deviceHeaderCss}`">
                <div :class="`opacity-100 duration-200`">
                    <transition name="fade" mode="out-in">
                        <span>{{screenName}}</span>
                    </transition>
                </div>
            </div>
            <!--Site wide alert: Mobile-->        
            <Notice v-if="!$store.state.noticeClosed && hasSitewideAlert && !isAdminScreen"
                :showClose="true"
                @close="closeNotice()"
                :class="`${!hasNotch ? 'mt-10' : 'pt-16'} fixed`"
                :isVert="true"
                :title="$store.state.fi.alert.title"
                :notice="$store.state.fi.alert.alert"
                :url="$store.state.fi.alert.url"
                :level="$store.state.fi.alert.level" />  
			<div :class="`${!isAdminScreen ? 'pt-16 md:pt-0' : 'pt-2 '} mn3-body-bg text-gray-700 font-sans `" :style="`min-height: 90vh`">
				<TopBar v-if="!isAdminScreen"
                    :class="`fixed hidden md:flex`" 
                    :mainNav="mainNavLinks"
                    :secondaryNav="customLinks"
                    :additionalNav="serviceLinks"
                    :links="navLinks"
                    :hasNotch="hasNotch" 
					@toggleNav="showSideNav = !showSideNav"     
                    @closeNotice="closeNotice()"               
                    :customLinks="customLinks"
					 />
                    <SideNav v-if="isAdminScreen"
                        :links="navLinks"
                        :customLinks="customLinks"
                        @expanded="updateSidenavExpansion" />
				<BottomBar v-if="$store.state.isLoggedIn && !keyboardShowing && !isAdminScreen" 
                    class="block md:hidden" 
                    :customLinks="customLinks"
                    :navLinks="navLinks" 
                    :mainNav="mainNavLinks"
                    :secondaryNav="customLinks"
                    :additionalNav="serviceLinks" />
				<div :class="`${contentTopPadding} 
                        ${this.$store.state.isMobileNavOpen ? 'opacity-20' : 'opacity-100'} 
                        duration-200 flex justify-between width-full border-0`"
                        >
					<div id="screen-padding-left" class="flex-grow text-right"></div>	
                    
                    <transition name="fade">				
					<div v-if="!$store.state.isMobileNavOpen" :class="`flex flex-col md:flex-row justify-center mx-auto w-full 
                        ${isAdminScreen ? 'ml-' + sideNavBuffer : ' max-w-screen-lg '}`">	
                        <div class="md:pt-4 w-full mx-auto px-0 md:px-0 box-border flex flex-wrap justify-center">
							<router-view v-slot="{ Component }">
								<transition name="route" mode="out-in">
									<component :is="Component"></component>
								</transition>
							</router-view>
						</div>					
					</div>
                    </transition>
					<div id="screen-padding-right" class="flex-grow text-left">					
					</div>
				</div>
				<div class="spacer flex-grow"></div>
			</div>
		</div>
        <div ref="notchCheck" style="height: env(safe-area-inset-top);" />
		<Footer v-if="!keyboardShowing && !isAdminScreen && !$store.state.isLoading" class="hidden md:flex" />
	</div>
</template>

<script>

import BottomBar from '@/components/BottomBar.vue'
import Footer from '@/components/Footer.vue'
import Icon from '@/components/Icon.vue'
import MobileNav from '@/components/MobileNav.vue'
import Notice from '@/components/Notice.vue'
import SideNav from '@/components/SideNav.vue'
import TopBar from '@/components/TopBar.vue'

import AppLogin from '@/views/adminmn/Login.vue'
import FinLogin from '@/views/adminfi/Login.vue'
import MemLogin from '@/views/membnet/Login.vue'

import { computed, ref } from "vue"
import { useRouter } from "vue-router"
import { useStore } from "vuex"
import { Keyboard } from '@capacitor/keyboard';
import { SplashScreen } from '@capacitor/splash-screen'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app'
import { Device } from '@capacitor/device'
import axios from "axios";

export default {
  components: {
	  	BottomBar,
        Footer,
		Icon,
		SideNav,
        TopBar,
        MobileNav,
        Notice,
		AppLogin,
		FinLogin,
		MemLogin
  },
	methods: {	
        appName(appId){
            let name = '';
            
            switch(appId){                
                //MN3 Android
                case 'com.mnolb.mn3fr3':
                    name = 'MN3Freedom3';
                break;
                
                //TCT Android
                case 'com.mnolb.tct':
                    name = 'TCT FCU MemberNet';
                break;
                
                //Spine Android
                case 'com.mnolb.spine':
                    name = 'Southern Pine CU Member.Net';
                break;

                //TopMark Android
                case 'com.mnolb.topmark':
                    name = 'TopMark FCU MemberNet';
                break;
                
                //LCU Android
                case 'com.mnolb.latvianmn':
                    name = 'Latvian Credit Union MemberNet';
                break;
                
                //Options Android
                case 'com.mnolb.ocu':
                    name = 'Options CU MemberNet';
                break;                
                
                //Central Nebraska Android
                case 'com.mnolb.cnfcu':
                    name = 'Central Nebraska FCU MemberNet';
                break;                
                
                //Thiokol Android
                case 'com.mnolb.tefcu':
                    name = 'Thiokol Elkton FCU MemberNet';
                break;
                
                //Cath Fam
                case 'com.mnolb.cfcu':
                    name = 'Catholic Family CU MemberNet';
                break;
                
                //Southernmost Android
                case 'com.mnolb.smfcu':
                    name = 'Southernmost FCU MemberNet';
                break;
            }            

            return name;
        
        },     
        closeNotice(){
            this.$store.state.noticeClosed = true;
            localStorage.setItem('closedNotice', this.$store.state.fi.alert.alert);
        },
        //Links for apps are hard coded in case app crashes out before fetching config info
        async openAppStore(){
            console.log('_openAppStore()');
        
            window.open(this.storeUrl);
        },
        toggleTimer() {
            if (this.isTimerRunning) {
                clearInterval(this.interval);
                console.log('timer stops');
            } else {
                this.interval = setInterval(this.incrementTime, 1000);
            }
            this.isTimerRunning = !this.isTimerRunning
        },
        incrementTime() {
            this.timerTime = parseInt(this.timerTime) + 1;
        },
		routeNameToScreenName(routeName){
			switch(routeName){
				case 'MemAccount':
					return 'Account';
				case 'MemAccounts':
					return 'Accounts';
                case 'MemAllied':
                    return this.$store.state.fi.alliedTitle;
                case 'MemAllied2':
                    return this.$store.state.fi.allied2Title;
                case 'MemIpay':
                    return this.$store.state.fi.ipayTitle;
				case 'MemBlank':
					return ' ';
				case 'MemCapture':
                case 'MemCaptureStage':
					return 'Remote Deposit Capture';
				case 'MemChat':
					return 'Secure Chat';
				case 'MemDevices':
					return 'Authorized Devices';
				case 'MemNotices':
					return 'Notices';
				case 'MemSettings':
					return 'Settings';
				case 'MemTransfer':
					return 'Transfer';
					
				case 'PubNotFound':
					return 'Screen Not Found';
				case 'PubRegister':
					return 'Account Registration';
			}
			return routeName;
		},
		getConfigError(){
			if(this.$store.state.isPhoneDemo) return false; //no error, debug phone demo
			if(!window.mn3_config) return "Could not read configuration";
			if(window.mn3_config.error){ 
                console.log(window.mn3_config);
                return window.mn3_config.error;
            }
			return false; //no error
		},
	},
	computed: {
        bodyPadding(){
            if(this.$store.state.isIos){
                return ' mb-24 ';
            }else{
                return ' mb-16 ';
            }
        },
        contentTopPadding(){

            let hDesktop = 24;
            let hMobile = 0;

            if(this.isAdminScreen)
                hDesktop = 4;

            //If notice showing, add extra height
            if(this.$store.state.fi.alert && !this.$store.state.noticeClosed){
                hDesktop += 16
                hMobile += 24;
            }

            return `pt-${hMobile} md:pt-${hDesktop}`

        },
        deviceHeaderCss(){
            
            //extra padding at to clear time display
            if(this.deviceInfo.platform == 'ios'){
                return ' pt-6';
            }else{
                return ' h-10';
            }
            //if(this.deviceInfo)
        },
        hasSitewideAlert(){
            if(!this.$store.state.fi.alert || !this.$store.state.fi.alert.alert || this.$store.state.fi.alert.alert.length == 0)
                return false;

            return true;
        },
		customCSSVars(){
			var theme = this.$store.state.theme;

			if(this.$store.state.isPhoneDemo){
				this.$store.commit("setTheme", theme)
			}
			return {
				// Navbars (top + bottom for small screens) 
				'--bg-navbar': theme.navbarBgColor,
				'--text-navbar': theme.navbarTextColor,
				'--bg-navbar-hover': theme.navbarBgHoverColor,
				'--text-navbar-hover': theme.navbarTextHoverColor, 
				'--border-navbar': theme.navbarBorderColor,
				'--bg-logo': theme.navbarLogoBgColor,

				// Body BG 
				'--bg-fade-from': theme.bodyBgTopColor, 
				'--bg-fade-to': theme.bodyBgBottomColor,
				'--body-text': theme.bodyTextColor,

                '--text-required': theme.requiredTextColor,
				
				// Content - table cels, standard menu options, text displays 
				'--bg-content': theme.contentBgColor,
				'--bg-content-hover': theme.contentBgHoverColor,
				'--text-content': theme.contentTextColor,
				'--text-content-hover': theme.contentTextHoverColor,
				'--border-content': theme.contentBorderColor,

				// Content Highlight 
				'--bg-content-highlight': theme.contentHighlightBgColor,
				'--bg-content-highlight-hover': theme.contentHighlightBgHoverColor,
				'--border-content-highlight': theme.contentHighlightBorderColor,
				'--text-content-highlight': theme.contentHighlightTextColor,
				'--text-content-highlight-hover': theme.contentHighlightTextHoverColor,

                // General hover highlighting
                '--bg-highlight': theme.contentHighlightBgColor,

				// Content Header
				'--bg-content-header': theme.contentHeaderBgColor,
				'--text-content-header': theme.contentHeaderTextColor,

                // Alt Content
				'--bg-content-alt': theme.contentAltBgColor,
				'--bg-content-alt-hover': theme.contentAltBgHoverColor,
				'--text-content-alt': theme.contentAltTextColor,
				'--text-content-alt-hover': theme.contentAltTextHoverColor,
				'--border-content-alt': theme.contentAltBorderColor,

				// Input
				'--bg-input': theme.inputBgColor,
				'--bg-input-hover': theme.inputBgHoverColor,
				'--text-input': theme.inputTextColor,
				'--border-input': theme.inputBorderColor,
				'--border-input-hover': theme.inputBorderHoverColor,

				// Button
				'--bg-button': theme.buttonBgColor,
				'--bg-button-hover': theme.buttonBgHoverColor,
				'--text-button': theme.buttonTextColor,	
				'--border-button': theme.buttonBorderColor,				

				// Submit
				'--bg-submit': theme.buttonSubmitBgColor,
				'--bg-submit-hover': theme.buttonSubmitBgHoverColor,
				'--text-submit': theme.buttonSubmitTextColor,	
				'--border-submit': theme.buttonSubmitBorderColor,	

				// Cancel
				'--bg-cancel': theme.buttonCancelBgColor,
				'--bg-cancel-hover': theme.buttonCancelBgHoverColor,
				'--text-cancel': theme.buttonCancelTextColor,
				'--border-cancel': theme.buttonCancelBorderColor,

				// Disabled
				'--bg-disabled': theme.inputDisabledBgColor,
				'--bg-disabled-hover': theme.inputDisabledBgHoverColor,
				'--text-disabled': theme.inputDisabledTextColor,
				'--border-disabled': theme.inputDisabledBorderColor,	

				// Focused
				'--bg-focused': theme.buttonFocusedBgColor,
				'--bg-focused-hover': theme.buttonFocusedBgHoverColor,
				'--text-focused': theme.buttonFocusedTextColor,
				'--border-focused': theme.buttonFocusedBorderColor,	

                // Credit/Debit
                '--text-credit': theme.creditTextColor,
                '--text-debit': theme.debitTextColor,

                // Approved
                '--bg-approved': theme.approvedBgColor,
                '--text-approved': theme.approvedTextColor,
                '--border-approved': theme.approvedBorderColor,
                
                // Denied
                '--bg-denied': theme.deniedBgColor,
                '--text-denied': theme.deniedTextColor,
                '--border-denied': theme.deniedBorderColor,
                
                // Pending
                '--bg-pending': theme.pendingBgColor,
                '--text-pending': theme.pendingTextColor,
                '--border-pending': theme.pendingBorderColor,

                // Alert
                '--bg-alert': theme.alertBgColor,
                '--bg-alert-hover': theme.alertBgHoverColor,
                '--text-alert': theme.alertTextColor,
                '--text-alert-hover': theme.alertTextHoverColor,
                '--border-alert': theme.alertBorderColor,
                '--border-alert-hover': theme.alertBorderHoverColor
						
			};
		},
        /* If this device has a notch at the top of the screen */
        hasNotch(){         
            return this.notchCheck.offsetHeight > 25;
        },
        storeName(){
            if(this.$store.state.isAndroid)
                return 'PlayStore';

            return 'AppStore';
        },
        storeUrl(){

            let url = '';
            if(this.$store.state.isAndroid){
                switch(this.appInfo.id){                
                    //MN3 Android
                    case 'com.mnolb.mn3fr3':
                        url = 'http://play.google.com/store/apps/details?id=com.mnolb.mn3fr3';
                    break;
                    
                    //TCT Android
                    case 'com.mnolb.tct':
                        url = 'https://play.google.com/store/apps/details?id=com.mnolb.tct';
                    break;
                    
                    //Spine Android
                    case 'com.mnolb.spine':
                        url = 'https://play.google.com/store/apps/details?id=com.mnolb.spine';
                    break;

                    //TopMark Android
                    case 'com.mnolb.topmark':
                        url = 'https://play.google.com/store/apps/details?id=com.mnolb.topmark';
                    break;
                    
                    //LCU Android
                    case 'com.mnolb.latvianmn':
                        url = 'https://play.google.com/store/apps/details?id=com.mnolb.latvianmn';
                    break;
                    
                    //Options Android
                    case 'com.mnolb.ocu':
                        url = 'https://play.google.com/store/apps/details?id=com.mnolb.ocu';
                    break;
                    
                    //Central Nebraska Android
                    case 'com.mnolb.cnfcu':
                        url = 'https://play.google.com/store/apps/details?id=com.mnolb.cnfcu';
                    break;
                    
                    //Thiokol Android
                    case 'com.mnolb.tefcu':
                        url = 'https://play.google.com/store/apps/details?id=com.mnolb.tefcu';
                    break;

                    //CathFam Android
                    case 'com.mnolb.cfcu':
                        url = 'https://play.google.com/store/apps/details?id=com.mnolb.cfcu';
                    break;

                    //Southernmost Android
                    case 'com.mnolb.smfcu':
                        url = 'https://play.google.com/store/apps/details?id=com.mnolb.smfcu';
                    break;
                }

            }else{
                switch(this.appInfo.id){                
                    //MN3 ios
                    case 'com.mnolb.mn3fr3':
                        url = 'https://apps.apple.com/us/app/mn3freedom3/id1628853356';
                    break;
                    
                    //TCT ios
                    case 'com.mnolb.tct':
                        url = 'https://apps.apple.com/us/app/tctfcu-member-net/id6470067251';
                    break;
                    
                    //Spine ios
                    case 'com.mnolb.spine':
                        url = 'https://apps.apple.com/us/app/southern-pine-cu-member-net/id6453235557';
                    break;

                    //TopMark ios
                    case 'com.mnolb.topmark':
                        url = 'https://apps.apple.com/us/app/topmark-fcu/id1613566175';
                    break;
                    
                    //LCU ios
                    case 'com.mnolb.latvianmn':
                        url = 'https://apps.apple.com/us/app/latvian-credit-union/id1633160159';
                    break;
                    
                    //Options ios
                    case 'com.mnolb.ocu':
                        url = 'https://apps.apple.com/us/app/options-cu-membernet/id6502660354';
                    break;
                    
                    //Central Nebraska ios
                    case 'com.mnolb.cnfcu':
                        url = 'https://apps.apple.com/us/app/central-nebraska-fcu-membernet/id6477541062';
                    break;
                    
                    //Thiokol ios
                    case 'com.mnolb.tefcu':
                        url = 'https://apps.apple.com/us/app/thiokol-elkton-fcu-member-net/id6499540275';
                    break;

                    //CathFam ios
                    case 'com.mnolb.cfcu':
                        url = 'https://apps.apple.com/us/app/catholic-family-cu-membernet/id6526502942';
                    break;

                    //Southernmost ios
                    case 'com.mnolb.smfcu':
                        url = 'https://apps.apple.com/us/app/southernmost-fcu-membernet/id6547832287';
                    break;
                }
            }
            return url;
        },
		validLogin(){			
			let cat = this.screenCategory;
			let validLogin = (cat === 'public' ||
				this.$store.state.isPhoneDemo || 
				(this.$store.state.isLoggedInAppAdmin && cat === 'appadmin') ||
				(this.$store.state.isLoggedInCuAdmin && cat === 'fiadmin') ||
				(this.$store.state.isLoggedIn && cat === 'membnet'));

			return validLogin;
		},
        isAdminScreen(){
            let cat = this.screenCategory;
            return cat == 'appadmin' || cat == 'fiadmin';
        },
        /* Type of current screen: account, deposit, devices transfer */
        screenType(){            
			if(this.$router.currentRoute === undefined
                || this.$router.currentRoute.value['name'] === undefined)
                return 'other';

            switch(this.$router.currentRoute.value['name']){
                case 'FinDash':
                case 'FinEvents':
                case 'FinLinks':
                case 'FinMembers':
                case 'FinChat':
                case 'FinSettings':
                    return 'admin';

                case 'MemAccount':
                case 'MemAccounts':
                    return 'account';
                
                case 'MemTransfer':
                case 'MemAllied':
                case 'MemAllied2':
                case 'MemIpay':
                    return 'transfer';

                case 'MemCapture':
                case 'MemCaptureStage':
                    return 'deposit';

                case 'MemDevices':
                    return 'devices';
            }

            return 'other';            
        },
        /* Area of the app: membnet, appadamin, fiadmin, public */
		screenCategory(){ 
			if(this.$router.currentRoute !== undefined){
				if(this.$router.currentRoute.value['name'] !== undefined){
					this.screenName = this.routeNameToScreenName(this.$router.currentRoute.value['name']);
					switch(this.$router.currentRoute.value['name'].substring(0,3)){
						case 'App':
							this.$store.state.screenCategory = 'appadmin';
							return 'appadmin';
						
						case 'Mem':
							this.$store.state.screenCategory = 'membnet';
							return 'membnet';

						case 'Fin':
							this.$store.state.screenCategory = 'fiadmin';
							return 'fiadmin';

						case 'Pub':
							this.$store.state.screenCategory = 'public';
							return 'public';					
					}
				}
			}
			return 'other';
		}
	},
	async created(){
        CapacitorUpdater.notifyAppReady()
        this.toggleTimer();

		let cfgurl = process.env.VUE_APP_MN3_CONFIG_URL;
		let useStaging = localStorage.getItem('use_staging') == '1';
		if(useStaging){
	            window.mn3_prod = false;
	            cfgurl = process.env.VUE_APP_MN3_STAGE_CONFIG_URL;
	    }else{
	            window.mn3_prod = true;
	    }
		if(Capacitor.isNativePlatform()){
                this.appInfo = await App.getInfo()
                //let appinfo = await App.getInfo();
				cfgurl += '?id=' + this.appInfo.id;

                this.$store.state.appBaseBuild = parseInt(this.appInfo.build);

                console.log('appBaseBuild: ', this.$store.state.appBaseBuild);
		}

        this.deviceInfo = await Device.getInfo();
        if(this.deviceInfo.platform == 'ios'){         
			this.$store.commit('setIsMobileDevice', true);         
			this.$store.commit('setIsIos', true);  
			this.$store.commit('setIsAndroid', false);

        }else if(this.deviceInfo.platform == 'android'){            
			this.$store.commit('setIsMobileDevice', true);       
			this.$store.commit('setIsAndroid', true);                   
			this.$store.commit('setIsIos', false);    
        }else{
			this.$store.commit('setIsMobileDevice', false);
			this.$store.commit('setIsAndroid', false);
			this.$store.commit('setIsIos', false);    
        }

		let cfgstart = performance.now();
		try{
			let cfg = await axios.get(cfgurl);
			window.mn3_config = cfg.data;
		}catch(ex){
			window.mn3_config = {
					error: "Could not read configuration (" + (performance.now() - cfgstart) + "ms): " + ex.toJSON().message
			};
		}
		this.configError = this.getConfigError();
		this.$api_install();

        //Check for setting update avail/req based on sent config vs baseBuild
        //ToDo: Get req/avail build numbers from server
        let serverReq = 0;
        let serverAvail = 0;

        if(this.$store.state.appBaseBuild < serverReq){
            this.$store.state.isUpdateRequired = true;
        }else if(this.$store.state.appBaseBuild < serverAvail){
            this.$store.state.isUpdateAvailable = true;
        }

		if(!this.configError){
			await this.$authapi.authCheck();

            let fi = window.mn3_config.fi;

			this.$store.commit('setFi', fi);

            let theme = window.mn3_config.theme;
			this.$store.commit('setTheme', theme);
            this.customLinks = fi.links;

            // set favicon 
            if(theme.faviconUrl != null && theme.faviconUrl != undefined){
                var link = document.querySelector("link[rel~='icon']");
                if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
                }
                link.href = theme.faviconUrl;
            }
            
            //Check if current notice has been closed
            if(fi.alert){
                if(fi.alert.alert == localStorage.getItem('closedNotice')){
                    this.$store.state.noticeClosed = true;
                }
            }

			// set body bg color to match bottom of fade gradient
			document.body.style.background = theme.bodyBgBottomColor
		}
		this.$store.commit('setIsLoading', false);
        this.$store.state.initialLoadComplete = true;

		//If logout notice is showing, hide after 5 seconds
		if(this.$store.state.showLogoutNotice){
			setTimeout(this.$store.commit('setShowLogoutNotice', false), 5000);
		}
	},
	setup(){		
        const appInfo = ref()
        const deviceInfo = ref();
		const store = useStore();
    	const router = useRouter();
		const showSideNav = ref(!JSON.parse(localStorage.getItem('sideNavAutoClose')))
		const keyboardShowing = ref(false)
		const configError = ref(false)
		const loginTimeout = ref(60)
		const screenName = ref(".")
        const sideNavBuffer = ref(52);
        const timerTime = ref(0);
        const timeoutWait = ref(30);
        const customLinks = ref([]);
        const notchCheck = ref(null);
        

        function updateSidenavExpansion(r){
            console.log(r.value);
            if(r.value){
                sideNavBuffer.value = '52';
            }else{
                sideNavBuffer.value = '16';
            }
        }

		const navLinks = computed(() => {
			let mship = store.state.memberships.find((el) => el.id.split('/').pop() == store.state.membershipId);
			let membStatus = "A";
			if(mship){
					membStatus = mship.attributes.status;
			}
			
			let links = [];
			switch(store.state.screenCategory){
				case 'appadmin':					
                    links.push({ to: `/adminmn/dash`, label: 'Dashboard', icon: 'navbar' });
					links.push({ to: `/adminmn/filist`, label: 'FI List', icon: 'branch' });
                    links.push({ to: `/adminmn/settings`, label: 'Settings', icon: 'cog' });

					break;
					
				case 'membnet':
					links.push({ to: '/accounts', label: 'Accounts', icon: 'users' });

                    if(membStatus != 'B')
					    links.push({ to: '/transfer', label: 'Transfer', icon: 'transfer' });

                    if(store.state.fi.linksPageEnabled)
					    links.push({ to: '/links', label: store.state.fi.linksPageTitle, icon: 'link' });
                    
                    if(store.state.fi.messagingEnabled)
					    links.push({ to: '/messages', label: 'Messages', icon: 'chat' });
                    
                    if(store.state.fi.rdcEnabled && membStatus != 'R' && membStatus != 'B')
					    links.push({ to: '/capture', label: 'Deposit', icon: 'chat' });
                    
                    if(store.state.fi.statementsEnabled || store.state.fi.noticesEnabled){
					    let title = '';
                        if(store.state.fi.statementsEnabled && store.state.fi.noticesEnabled)   
                            title = 'Statements & Notices';
                        else if(store.state.fi.statementsEnabled)
                            title = 'Statements';
                        else 
                            title = 'Notices';

                        links.push({ to: '/statements', label: title, icon: 'receipt' });
                    }

                    if(store.state.fi.alliedEnabled  && membStatus != 'B')
					    links.push({ to: '/allied', label: store.state.fi.alliedTitle, icon: 'receipt' });
                    
                    if(store.state.fi.allied2Enabled  && membStatus != 'B')
					    links.push({ to: '/allied2', label: store.state.fi.allied2Title, icon: 'receipt' });
                        
                    if(store.state.fi.ipayEnabled && membStatus != 'B')
					    links.push({ to: '/ipay', label: store.state.fi.ipayTitle, icon: 'receipt' });
                        
					break;

				case 'fiadmin':                    
					links.push({ to: `/admin/dash`, label: 'Dashboard', icon: 'window' })
					
                    if(store.state.permissions['all'] || store.state.permissions['event-monitor'])
                        links.push({ to: `/admin/events`, label: 'Event Monitor', icon: 'presentation-chart-line' })
					
                    
                    if(store.state.permissions['all'] || store.state.permissions['member-read'])
                        links.push({ to: `/admin/members`, label: 'Members', icon: 'users' })
                
                    if(store.state.permissions['all'] || store.state.permissions['messaging'])
	    				links.push({ to: `/admin/chat`, label: 'Secure Messaging', icon: 'envelope' })
                    
                    if(store.state.permissions['all'] || store.state.permissions['unlock'])
                        links.push({ to: `/admin/unlock`, label: 'Unlock Accounts', icon: 'lock-open' })
                    
                    if(store.state.fi.ccpaymentEnabled && (store.state.permissions['all'] || store.state.permissions['cc-payments']))
					    links.push({ to: `/admin/cardpayments`, label: 'Card Payments', icon: 'credit-card' }) 

                    if(store.state.permissions['all'] || store.state.permissions['loan-apps'])
					    links.push({ to: `/admin/loanapps`, label: 'Pending Loans', icon: 'clipboard-document-check' })
					
                    if(store.state.permissions['all'] || store.state.permissions['settings']){
                        links.push({ to: `/admin/settings`, label: 'Settings', icon: 'cog' })
					    links.push({ to: `/admin/links`, label: 'Customize', icon: 'link' })     
                    }     
                    
                    if(store.state.permissions['all'] || store.state.permissions['admins'])
                        links.push({ to: `/admin/uaccess`, label: 'User Access', icon: 'user' })      

					break;

				case 'public':
					break;			
			}

			return links;
		});


		try{
			Keyboard.addListener('keyboardDidShow', info => {
			keyboardShowing.value = true
			});

			Keyboard.addListener('keyboardDidHide', () => {
			keyboardShowing.value = false
			});
		}catch(ex){
		}

		return {
            appInfo,
            customLinks,
            deviceInfo,
			keyboardShowing,
			loginTimeout,
			navLinks,
            notchCheck,
			router,
			screenName,
			showSideNav,
            sideNavBuffer,
            timerTime,
            updateSidenavExpansion,
            configError,
            timeoutWait
		}
	}
}
</script>


<style src="./assets/tailwind_built.css" />
<style src="./assets/css/mn3.css" />
<style src="./assets/css/fonts.css" />
<style src="./assets/css/smooth-label.css" />
<style src="./assets/css/transitions.css" />
