<template>
    <div v-if="editMode == 'link'"
        class="flex flex-col justify-start mx-auto w-5/6 mn3-content rounded-sm p-4">
        <div class="text-lg font-bold">Edit Link</div>
<div class="text-sm p-1 rounded-sm mb-1 mn3-highlight text-center flex flex-row">
    <span class="flex-grow text-center">Below is a preview of how the link will appear within its group. Click any tile to edit it, this will save your current changes.</span>
    <icon id="mobile" @click="skinnyPreview = true" :class="`hidden ${skinnyPreview ? 'opacity-100' : 'opacity-50 hover:opacity-100'} duration-150 cursor-pointer h-6 w-6 mr-2`" />
    <icon id="pc" @click="skinnyPreview = false"   
        :class="`hidden ${!skinnyPreview ? 'opacity-100' : 'opacity-50 hover:opacity-100'} duration-150 cursor-pointer h-6 w-6 mr-2`" />
</div>
        <!--Grid Preview, non-focused tiles slightly faded-->
        <div :class="`mx-auto flex flex-wrap overflow-y-auto mb-10`" 
            :style="`max-height: 500px;${skinnyPreview ? 'width: 400px' : 'width: 100%;'}`">
            <div v-for="(l, i) in serviceLinksByGroup(editingLink.attributes.group)" :key="l.attributes.url"                            
                :class="`${tileWidth(l)}`"
                title="Click to edit this link">
                    <LinkTile
                        v-if="l.attributes != undefined"
                        @click="saveLinkChanges(editingLink);editingLink = l"
                        :theme="l.attributes.style != undefined ? l.attributes.style : group.style "
                        :title="l.attributes.title"
                        :description="l.attributes.description"
                        :icon="l.attributes.icon"
                        :size="l.attributes.size"
                        height="40"
                        />
                    <div class="hidden">{{l}}</div>
            </div>
        </div>

        <!--Edit link form-->
        <CustomLinkEdit :i="i" :link="editingLink" 
            screen="services"
            :groups="serviceGroups"
            :editOnly="true"
            @saveChanges="saveLinkChanges"
            @deleteLink="deleteLink" 
            @editLink="editLink"
            @moveUp="moveUp"
            @moveDown="moveDown"
            @moveLink="moveLink" />

            
        <div class="w-full flex flex-row mt-2">
                        <Button @click="saveLinkChanges(editingLink);editingLink = ''; editMode = '';" class="flex-grow" type="submit" label="Save Changes" />
                        <Button @click="editingLink = '';editMode = '';" type="cancel" label="Cancel" />
                    </div>
    </div>
    <div v-else class="flex flex-col w-full max-w-screen-lg ">
    <!--Required Links Display-->
    <div class="flex flex-col text-sm mx-auto mn3-content-header p-2 rounded-sm mb-5">
        <div class="flex flex-row items-center">
            <div class="font-bold">Required Screens:</div>
            <div v-for="screen in requiredScreens" :key="screen"
                :class="`${!isScreenUsed(screen) ? 'mn3-alert' : 'font-bold'} capitalize mx-3 py-1 px-2 flex flex-row items-center rounded-sm`">
                <Icon :id="!isScreenUsed(screen) ? 'x-mark' : 'check'" class="h-4 w-4 mr-1" />{{screen}}</div>
        </div>
        <div class="text-xs text-center mt-1 flex flex-row justify-center items-center">
            <Icon id="info-line" class="h-7 w-7 mr-1" />
            <span>These are the main screens of MemberNet and must be included as either <b>Main</b> or <b>Additional</b> navigation links.</span>
        </div>
    </div>
    <!--Main Header Links -->
    <div class="w-full flex flex-col p-2 max-w-screen-lg mx-auto mb-5 mn3-content">
        <div class="mn3-navbar p-2 rounded-sm font-bold flex flex-row text-lg">
            <Icon id="bolt" class="h-6 w-6 mr-2" />
            <span>Main Navigation Links</span>
        </div>

        <div class="text-sm mn3-content-alt flex flex-row items-center p-1 rounded-t mt-1">
            <Icon id="pc" class="h-5 w-5 mr-1" />
            <div class="w-16 font-bold">Desktop:</div><span>Displayed at the top of the header of every screen</span>
        </div>
        <div class="text-sm mn3-content-alt flex flex-row items-center p-1 rounded-b">
            <Icon id="mobile" class="h-5 w-5 mr-1" />
            <div class="w-16 font-bold">Mobile:</div><span>Displayed as icons on the main navigation bar of every screen</span>
        </div>

        <div class="flex flex-row py-2 items-start">

            <div v-for="i in 3" :key="i" 
                class="w-1/3 flex flex-col items-start text-lg justify-center">

                <div class="flex flex-row items-center justify-start p-2">
                    <label :for="`mainnav${i}`" class="font-bold leading-3">Link {{i}} <div v-if="i == 3 && this.$store.state.fi.rdcEnabled" class="text-xs">Desktop</div></label>
                    <Icon :id="headerOptions[mainNav[i-1]] != undefined ? headerOptions[mainNav[i-1]].icon : 'icon-select'" class="h-6 w-6 mx-2" />
                    <select v-model="mainNav[i-1]"
                        :id="`mainnav${i}`"
                        @change="saveMainNav()"
                        class="py-2 rounded-sm">
                        <option v-for="opt in headerOptions"
                            :key="opt.value"
                            :value="opt.value">
                            {{opt.label}}
                        </option>
                    </select>
                </div>

                <!--If opton 3 and RDC enabled, force mobile to RDC and show desktop option-->
                <div v-if="i == 3 && this.$store.state.fi.rdcEnabled"
                    title="This option is automatically set to Remote Deposit for mobile devices"
                    class="flex flex-row items-center justify-start mt-2 cursor-default mn3-content-alt w-full p-2 rounded-sm">
                    <div class="font-bold leading-3">Link 3 <div class="text-xs">Mobile</div></div>
                    <Icon id="camera" class="h-6 w-6 mx-2" />
                    <div class="font-bold ml-1">Remote Deposit</div>
                </div>
                
            </div>

        </div>
    </div>


    <!--Custom/Service Links-->
    <div class="flex flex-row w-full justify-between">
        <div v-for="screen in linkCategories" :key="screen"
            style="width: 495px"
            class="mb-6 mn3-content rounded-sm p-2 ">                       

            <div :class="`${screen == 'custom' ? 'rounded-sm' : 'rounded-t-sm'} w-full mn3-navbar p-2 flex items-center justify-between font-bold`">                
                <Icon :id="screen == 'custom' ? 'rectangle-stack' : 'briefcase'" class="h-6 w-6 mr-2" />
                <div class="uppercase">{{screen == 'custom' ? 'Additional Nav' : 'Service'}} Links: {{linksByScreen(screen).length}} Link(s)</div>
                <div class="flex-grow" />
                
                <Button v-if="screen == 'custom' && customLinks.length <4" @click="addCustomLink()" icon="link" label="Add Link" text="sm" class="uppercase" />
                <div v-else-if="screen == 'custom'" class="py-1">Max Links Added</div>
                <Button v-else @click="addingGroup = true" icon="queue-list" label="Add Group" text="sm" class="uppercase" />
            </div>            
            <div v-if="screen == 'services'"
                class="w-full mn3-navbar px-2 pb-2 rounded-b-sm flex items-center justify-between text-sm">
                <div class="flex flex-col">
                    <div class="font-bold">Groups are an optional way to organize service links</div>
                    <div>Ungrouped links are displayed after all groups</div>
                </div>
            </div>
            
            <div class="text-sm mn3-content-alt flex flex-row items-center p-1 rounded-t mt-1">
                <Icon id="pc" class="h-5 w-5 mr-1 flex-shrink-0" />
                <div class="w-20 font-bold flex-shrink-0 flex-grow-0">Desktop:</div>
                <span v-if="screen == 'custom'">Up to 4 links, displayed in the header of every screen</span>
                <span v-else class="leading-3 pt-1">Displayed in pop-in side nav as text links and on the services screen as tiles</span>
            </div>
            <div class="text-sm mn3-content-alt flex flex-row items-center p-1 rounded-b">
                <Icon id="mobile" class="h-5 w-5 mr-1 flex-shrink-0" />
                <div class="w-20 font-bold flex-shrink-0 flex-grow-0">Mobile:</div>
                <span v-if="screen == 'custom'">Displayed in the main navigation menu</span>
                <span v-else>Displayed on services screen as tiles</span>
            </div>
            
            
            <!--Custom/Additional Nav Links, Display all For Screen-->
            <div v-if="screen == 'custom'" class="w-full">
                <div v-for="(l, i) in linksByScreen(screen)" :key="i"
                    class="flex flex-col mb-8 mn3-content-hove p-1 m-2 rounded-sm">
                    <CustomLinkEdit :i="i" :link="l" :screen="screen"
                        @saveChanges="saveLinkChanges"
                        @deleteLink="deleteLink" 
                            @editLink="editLink"
                        @moveUp="moveUp"
                        @moveDown="moveDown"
                        @moveLink="moveLink" />
                </div>   
            </div>
            
            <div v-else class="w-full">

                <!--Adding New Group/Editing Group-->
                <div v-if="addingGroup" class="w-full flex flex-col">
                    <div class="w-full mn3-content-header font-bold text-lg mt-5 rounded-sm p-2 flex flex-row justify-between">
                        <div>Add Link Group</div>
                    </div>
                    <div class="w-full flex flex-row items-center">
                        <input v-model="addGroupName"
                            placeholder="Group Name"
                            :class="`${screen=='custom' ? 'customLinkTitle' : 'serviceLinkTitle'} pt-3 pb-2 block flex-grow px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none 
                                focus:outline-none focus:ring-0 focus:border-black mn3-input-text`" />
                    </div>       
                    <div class="flex flex-row w-full flex-wrap items-center py-2">
                        <!--Icon Select-->
                        <div class="flex flex-row items-center w-full cursor-pointer" @click="showIconSelect = !showIconSelect"><Icon :id="addGroupIcon" class="h-6 w-6 mr-2" title="Link Icon" />
                        <div class="m3-input-border border-b-2 py-1 flex flex-row items-center w-full">
                            <div class="font-bold">Link icon:</div>
                            <div class="capitalize pl-2 flex-grow">{{addGroupIcon}}</div>
                        </div>
                        </div>

                        <!--Slideshow seting-->
                        <label class="p-2 font-bold flex flex-row items-center"><input class="h-6 w-6 mr-2" type="checkbox" v-model="addGroupSlideshow" /> Group is a Slideshow</label>             
            
                    </div>
        
                    <!--Icon Select Grid-->
                    <div v-if="showIconSelect" class="w-full flex flex-col mb-5">
                        <div class="h-12 md:h-8 flex flex-grow ml-2 md:ml-0">
                            <input type="text" 
                                class="mn3-content rounded-sm p-1 border text-sm w-full" 
                                placeholder="Filter Icons" 
                                v-model="iconFilter"   />
                        </div>
                        <div class="w-full flex flex-row flex-wrap overflow-y-auto" style="max-height: 500px">
                            <div v-for="icon in icons" :key="icon" 
                                @click="addGroupIcon = icon; showIconSelect = false;"
                                :title="icon"
                                :class="`${iconFilter != '' && icon.indexOf(iconFilter) == -1 ? 'hidden' : ''} w-1/6 p-2 opacity-50 hover:opacity-100 duration-100 cursor-pointer`">
                                <Icon :id="icon" />
                                <div v-if="iconFilter != ''" class="text-xs w-full text-center leading-3">{{icon}}</div>
                            </div>
                        </div>
                        <div class="w-full flex flex-row">
                            <Button @click="showIconSelect = false" class="flex-grow" type="cancel" label="Cancel Icon Select" />
                        </div>
                    </div>
                    <div class="w-full flex flex-row mt-2">
                        <Button @click="addServicesGroup()" class="flex-grow" type="submit" label="Add Group" />
                        <Button @click="addingGroup = false" type="cancel" label="Cancel" />
                    </div>

                </div>

                <!--Service Link Groups-->
                <div v-for="group in serviceGroups" :key="group">
                    <div class="w-full mn3-content-header font-bold text-lg mt-5 rounded-sm p-2 flex flex-row items-center justify-between">
                        <Icon :id="group.attributes.icon" class="h-6 w-6 mr-2" />
                        <div class="flex-grow">{{group.attributes.titleUse}}</div>
                        <Button @click="beginEditGroup(group)" icon="pencil" title="Edit Group" class="mr-2" />
                        <Button @click="confirmDeleteGroup = group.id" icon="trash" title="Delete Group and Links" class="mr-2" />
                        <Button @click="addServicesLink(group.id)" icon="link" label="Add Link" title="Add link to group" text="sm" 
                            class="uppercase" />
                    </div>
                    
                    <!-- Edit Group -->
                    <div v-if="editGroupId == group.id" class="w-full flex flex-col">
                        <div class="w-full flex flex-row items-center">
                            <label for="groupTitle" class="cursor-pointer"><Icon id="pencil" class="h-6 w-6 mr-2" title="Group Title" /></label>
                            <input v-model="editingInfo['groups'][group.id]['title']"
                                id="groupTitle"
                                placeholder="Group Name"
                                :class="`${screen=='custom' ? 'customLinkTitle' : 'serviceLinkTitle'} pt-3 pb-2 block flex-grow px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none 
                                    focus:outline-none focus:ring-0 focus:border-black mn3-input-text`" />
                        </div>       
                        <div class="flex flex-row w-full flex-wrap items-center py-2">
                            <!--Icon Select-->
                            <div class="flex flex-row items-center w-full cursor-pointer" @click="showIconSelect = !showIconSelect">
                                <Icon :id="editingInfo['groups'][group.id]['icon']" class="h-6 w-6 mr-2" title="Link Icon" />
                            <div class="m3-input-border border-b-2 py-1 flex flex-row items-center w-full">
                                <div class="font-bold">Link icon:</div>
                                <div class="capitalize pl-2 flex-grow">{{editingInfo['groups'][group.id]['icon']}}</div>
                            </div>
                            </div>

                            <!--Slideshow seting-->
                            <label class="p-2 font-bold flex flex-row items-center">
                                <input class="h-6 w-6 mr-2" type="checkbox" v-model="editingInfo['groups'][group.id]['slideshow']" /> Group is a Slideshow
                            </label>            
                
                        </div>
            
                        <!--Icon Select Grid-->
                        <div v-if="showIconSelect" class="w-full flex flex-col mb-5">
                            <div class="h-12 md:h-8 flex flex-grow ml-2 md:ml-0">
                                <input type="text" 
                                    class="mn3-content rounded-sm p-1 border text-sm w-full" 
                                    placeholder="Filter Icons" 
                                    v-model="iconFilter"   />
                            </div>
                            <div class="w-full flex flex-row flex-wrap overflow-y-auto" style="max-height: 500px">
                                <div v-for="icon in icons" :key="icon" 
                                    @click="editingInfo['groups'][group.id]['icon'] = icon; showIconSelect = false;"
                                    :title="icon"
                                    :class="`${iconFilter != '' && icon.indexOf(iconFilter) == -1 ? 'hidden' : ''} w-1/6 p-2 opacity-50 hover:opacity-100 duration-100 cursor-pointer`">
                                    <Icon :id="icon" />
                                    <div v-if="iconFilter != ''" class="text-xs w-full text-center leading-3">{{icon}}</div>
                                </div>
                            </div>
                            <div class="w-full flex flex-row">
                                <Button @click="showIconSelect = false" class="flex-grow" type="cancel" label="Cancel Icon Select" />
                            </div>
                        </div>
                        <div class="w-full flex flex-row mt-2">
                            <Button @click="updateGroup(group)" class="flex-grow" type="submit" label="Save Group" />
                            <Button @click="editGroupId = '';" type="cancel" label="Cancel" />
                        </div>
                    </div>

                    <!--Delete Group Confirm-->
                    <div v-else-if="confirmDeleteGroup == group.id"  class="flex flex-col cursor-pointer mn3-alert p-1 rounded-sm justify-between">
                        <span class="w-full text-center mb-1 font-bold">Are you sure you want to delete this link group?</span>
                        <div class="flex flex-row w-full justify-between">
                            <Icon :id="group.attributes.icon" class="h-12 w-12 mr-1" />
                            <div class="flex flex-col">
                                <span class="font-bold">{{group.attributes.title}}</span>
                            </div>
                            <div class="flex-grow" />
                        </div>
                        <div class="flex flex-row justify-between mt-1">
                            <Button @click="deleteServicesGroup(group)" class="flex-grow" label="Yes, Delete Group" />
                            <Button @click="confirmDeleteGroup = ''" type="cancel" label="Cancel" />
                        </div>
                    </div>     
                    <div v-for="(l, i) in serviceLinksByGroup(group.id)" :key="i"
                        class="flex flex-col mb-8 mn3-content-hove p-1 m-2 rounded-sm">
                        <CustomLinkEdit :i="i" 
                            :link="l" 
                            :screen="screen"
                            :groups="serviceGroups"
                            @saveChanges="saveLinkChanges"
                            @deleteLink="deleteLink" 
                            @editLink="editLink"
                            @moveUp="moveUp"
                            @moveDown="moveDown"
                            @moveLink="moveLink" />
                    </div> 
                </div>

                <!--Service Links, Display by Group first then ungrouped-->
                <div class="w-full mn3-content-header font-bold text-lg mt-5 rounded-sm p-2 flex flex-row justify-between">
                    <div>Ungrouped Links</div>
                    <Button @click="addServicesLink('')" icon="link" label="Add Link" text="sm" class="uppercase" />
                </div>
                <div v-for="(l, i) in serviceLinksByGroup()" :key="i"
                    class="flex flex-col mb-8 mn3-content-hove p-1 m-2 rounded-sm">
                    <CustomLinkEdit :i="i" :link="l" :screen="screen"
                        :groups="serviceGroups"
                        @saveChanges="saveLinkChanges"
                        @deleteLink="deleteLink" 
                        @editLink="editLink"
                        @moveUp="moveUp"
                        @moveDown="moveDown"
                        @moveLink="moveLink" />
                </div> 

            </div>  



            <!--If RDC enabled and custom links, add uneditable last option. Mobile = Desktop Nav3 link, Desktop = RDC-->
            <div v-if="this.$store.state.fi.rdcEnabled && screen == 'custom'"
                title="This option is automatically set to Remote Deposit for mobile devices"
                class="flex flex-col p-2 rounded-sm mn3-content-alt">
                <div class="p-2 rounded-sm mn3-content-alt flex flex-row items-center justify-start">
                    <Icon id="rectangle-stack" class="h-6 w-6 mr-2" />
                    <span class="font-bold mr-1">Final Additional Nav Link</span>
                </div>
                
                <div class="flex flex-row mn3-content-alt p-1 rounded-sm justify-start items-center w-full">
                    <div class="p-1 mr-2 flex flex-col items-center w-16">
                        <Icon id="pc" class="h-8 w-8" />
                        <span class="text-xs uppercase font-bold">Desktop</span>
                    </div>
                    <Icon id="camera" class="h-14 w-14 mx-5" />
                    <div class="flex flex-col overflow-hidden whitespace-nowrap" style="width: 250px">
                        <span class="font-bold whitespace-nowrap">Remote Deposit</span>
                        <span class="whitespace-nowrap">/capture</span>
                    </div>
                    <div class="flex-grow" />
                </div>  
                
                <div class="flex flex-row mn3-content-alt p-1 rounded-sm justify-start items-center w-full">
                    <div class="p-1 mr-2 flex flex-col items-center w-16">
                        <Icon id="mobile" class="h-8 w-8" />
                        <span class="text-xs uppercase font-bold">Mobile</span>
                    </div>
                    <Icon id="document" class="h-14 w-14 mx-5" />
                    <div class="flex flex-col overflow-hidden whitespace-nowrap" style="width: 250px">
                        <span class="font-bold whitespace-nowrap" v-if="headerOptions[mainNav[2]] != undefined">{{headerOptions[mainNav[2]].label}}</span>
                        <span class="whitespace-nowrap">/{{mainNav[2]}}</span>
                    </div>
                    <div class="flex-grow" />
                </div>  
                
                <div class="flex flex-row items-center text-sm mt-2">
                    <icon id="info" class="h-4 w-4 mr-2 flex-shrink-0" />
                    <span class="font-italic">The final link is automatically set to display Remote Deposit on Desktop and on Mobile will display the option you have selected for Main Navigation Desktop Link 3.</span>
                </div>
                
            </div>
            
        </div>
    </div>
</div>

</template>

<script>
	import Form from "@/components/Form.vue";
	import Field from "@/components/Field.vue";
	import Button from "@/components/Button.vue";
	import Icon from "@/components/Icon.vue";
	import LinkTile from "@/components/LinkTile.vue";
    import CustomLinkEdit from "@/components/CustomLinkEdit.vue";

    import { ref, computed } from 'vue'
    import { useRouter } from "vue-router"

	export default {
		components:{
            Button,
            CustomLinkEdit,
            Icon,
            LinkTile,
			Form,
			Field
		},
		data(){
			return {
			};
		},

		created(){
            document.title = 'Customize Links';
            this.frameHeightUpdate++;
            window.addEventListener("resize", this.windowResize);

            //Set main nav values
            this.mainNav[0] = this.$store.state.fi.mainNav1;
            this.mainNav[1] = this.$store.state.fi.mainNav2;
            this.mainNav[2] = this.$store.state.fi.mainNav3;

            this.loadLinkGroups();
            this.loadLinks();
		}, 
        computed: {
            headerOptions(){
                let options = {};

                options['accounts'] = { label: 'Accounts', value: 'accounts', icon: 'users' };
                options['transfer'] = { label: 'Transfer', value: 'transfer', icon: 'transfer' };

                
                if(this.$store.state.fi.statementsEnabled && this.$store.state.fi.noticesEnabled )
                    options['statements'] = { label: 'Statements & Notices', value: 'statements', icon: 'document' };                
                else if(this.$store.state.fi.statementsEnabled)
                    options['statements'] = { label: 'Statements', value: 'statements', icon: 'document' };                
                else if(this.$store.state.fi.noticesEnabled)
                    options['statements'] = { label: 'Notices', value: 'statements', icon: 'document' };                
               
                
                //RDC is auto set as third main nav option for mobile, last secondary nav option for desktop
                //if(this.$store.state.fi.rdcEnabled)
                //    options['capture'] = { label: 'Remote Deposit', value: 'capture', icon: 'camera' };   
                
                if(this.$store.state.fi.alliedEnabled)
                    options['allied'] = { label: 'Allied', value: 'allied', icon: 'ticket-2' };   
                
                if(this.$store.state.fi.allied2Enabled)
                    options['allied2'] = { label: 'Allied2', value: 'allied2', icon: 'ticket-2' };   
                
                if(this.$store.state.fi.ipayEnabled)
                    options['ipay'] = { label: 'Ipay', value: 'ipay', icon: 'ticket-2' };   
                
                return options;
            },
            // All screens that should be linked to, includes FI unlocked features
            requiredScreens(){
                let screens = ['accounts', 'transfer'];

                if(this.$store.state.fi.statementsEnabled || this.$store.state.fi.noticesEnabled)
                    screens.push('statements');
                
                if(this.$store.state.fi.rdcEnabled)
                    screens.push('capture');

                if(this.$store.state.fi.alliedEnabled)
                    screens.push('allied');
                
                if(this.$store.state.fi.allied2Enabled)
                    screens.push('allied2');

                if(this.$store.state.fi.ipayEnabled)
                    screens.push('ipay');

                return screens;
                
            },
            linkCategories(){                
                if(this.$store.state.fi.linksPageEnabled){
                    return ['custom', 'services'];
                }
                return ['custom'];
            },
            //Return service links that have a group that has been deleted
            orphanedServiceLinks(){
                let orph = [];

                for(var ii = 0; ii < this.serviceLinks.length; ii++){

                    let l = this.serviceLinks[ii];
                    if(l.attributes.group != '' && !this.groupExists(l.attributes.group))
                        orph.push(l);
                }

                return orph;
            }
        },
        methods: {    
            async updateGroup(group){
                
                let titleFull = this.editingInfo['groups'][group.id].title;
                if(this.editingInfo['groups'][group.id]['slideshow'])
                    titleFull += '__slide';

                let data = {
                    "data": {
                        "attributes": {
                            title: titleFull,
                            icon: this.editingInfo['groups'][group.id].icon
                        }
                    }
                }

                let postUrl = group.links.self;

                try{
                    let resp = await this.$fapi.patch(postUrl, data);                     
                    
                    this.loadLinkGroups();
                    this.loadLinks();

                    this.editGroupId = '';
                }catch(resp){
                    let errorDetail = resp.response.data.errors[0].detail;
                    let errorTitle = resp.response.data.errors[0].title;
                    if(errorDetail != undefined){
                        this.error = 'Error: ' + errorDetail;
                    }else if(errorTitle != undefined){
                        this.error = 'Error: ' + errorTitle;
                    }else{
                        this.error = 'Error: There was an issue saving the group';
                    }
                }
                
            },
            async beginEditGroup(group){
            
                if(this.editingInfo['groups'] == undefined)
                    this.editingInfo['groups'] = {};

                this.editingInfo['groups'][group.id] = {
                    icon: group.attributes.icon,
                    title: group.attributes.titleUse,
                    slideshow: group.attributes.title.indexOf('__slide') != -1
                }


                //Delay to prevent issue with screen rendering element before info set
                await new Promise(resolve => setTimeout(resolve, 5));
                this.editGroupId = group.id

            },
            //Check if a screen is used in any navigation links
            isScreenUsed(screen){
                
                //Check main nav links
                if(this.mainNav.includes(screen) || screen == 'capture')
                    return true;

                //Check secondary nav links
                for(var ii = 0; ii < this.customLinks.length; ii++){
                    let link = this.customLinks[ii];
                    if(link.attributes.url == screen || link.attributes.url == '/' + screen)
                        return true;
                }

                return false;
            },
            linksByScreen(screen){
                if(screen == 'custom'){
                    return this.customLinks;
                }else{
                    return this.serviceLinks;
                }
            },   
            async saveMainNav(){
                
                let data = {
                    data: {
                        attributes: {
                            mainNav1: this.mainNav[0],
                            mainNav2: this.mainNav[1],
                            mainNav3: this.mainNav[2]
                        }
                    }
                }
                await this.$fapi.patch("", data)
            },
            serviceLinksByGroup(groupId){
                let links = [];
                let serviceLinks = this.linksByScreen('service');
                for(var ii = 0; ii < serviceLinks.length; ii++){
                    let l = serviceLinks[ii];
                    if(((groupId == undefined || groupId.length == 0) && 
                    (l.attributes.group == undefined || l.attributes.group.length == 0))
                        ||l.attributes.group == groupId){
                        links.push(l);
                    }
                }
                return links;
            },
            tileWidth(service){
                if(service.attributes.size == 3){
                    return 'w-full';
                }
                else if(service.attributes.size == 2){
                    return 'w-full sm:1/2 lg:w-1/2 flex-grow';
                }
                else return 'w-1/3 md:w-1/4 lg:w-1/5 flex-grow';
            },
            windowResize(e){
                this.frameHeightUpdate++;
            },
            async addServicesGroup(){
                
                if(this.addGroupName.length > 0){

                    
                    let postUrl = 'links_page_groups';
                    let nameUse = this.addGroupName;
                    if(this.addGroupSlideshow)
                        nameUse += '__slide';
                    let data = {
                        "data": {
                            "attributes": {
                                title: nameUse,
                                icon: this.addGroupIcon
                            }
                        }
                    }

                    try{
                        let resp = await this.$fapi.post(postUrl, data); 
                    }catch(resp){
                        let errorDetail = resp.response.data.errors[0].detail;
                        let errorTitle = resp.response.data.errors[0].title;
                        if(errorDetail != undefined){
                            this.error = 'Error: ' + errorDetail;
                        }else if(errorTitle != undefined){
                            this.error = 'Error: ' + errorTitle;
                        }else{
                            this.error = 'Error: There was an issue saving the link';
                        }
                    }


                    this.addingGroup = false;
                    this.addGroupName = '';
                            
                    this.loadLinkGroups();
                    this.loadLinks();
                }
            },
            async addCustomLink(){

                //Make sure that there aren't already 4 custom links
                if(this.customLinks.length > 3){
                    console.log('Too many custom links');
                }
                else{
                    this.customLinks.push({
                        new: true,
                        type: 'CustomLinks',
                        editing: true,
                        attributes:{
                            url: '',
                            title: '',
                            description: '',
                            group: '',
                            style: 'default',
                            size: '1',
                            icon: 'web'
                        }
                    });

                    //Focus new link title input, delay so input is found by query
                    await new Promise(resolve => setTimeout(resolve, 5));
                    let inputs = document.querySelectorAll(".customLinkTitle");
                    inputs[inputs.length-1].focus()
                }
            },
            async addServicesLink(group){
                this.serviceLinks.push({
                    new: true,
                    type: 'ServicesLinks',
                    editing: true,
                    attributes:{
                        url: '',
                        title: '',
                        icon: 'web',
                        description: '',
                        style: 'default',
                        size: '1',
                        group: group
                    }
                });

                //Focus new link title input, delay so input is found by query
                await new Promise(resolve => setTimeout(resolve, 5));
                let inputs = document.querySelectorAll(".serviceLinkTitle");
                inputs[inputs.length-1].focus()
            },
            async deleteServicesGroup(group){
                
                
                try{
                    let resp = await this.$fapi.delete(group.links.self); 
                    link.deleting = false;
                    this.loadLinkGroups();
                    this.loadLinks();
                }catch(resp){
                    let errorDetail = resp.response.data.errors[0].detail;
                    let errorTitle = resp.response.data.errors[0].title;
                    if(errorDetail != undefined){
                        this.error = 'Error: ' + errorDetail;
                    }else if(errorTitle != undefined){
                        this.error = 'Error: ' + errorTitle;
                    }else{
                        this.error = 'Error: There was an issue saving the update';
                    }
                }
            },
            groupExists(groupId){
                for(var ii = 0; ii < this.serviceGroups.length; ii++){
                    if(this.serviceGroups[ii].id == groupId)
                        return true;
                }
                return false;
            },
            removeCustomLink(index){
                this.customLinks.splice(index, 1);
            },
            removeServicesLink(index){
                this.serviceLinks.splice(index, 1);
            },
            cancelChanges(link){
                link.editing = false;
            },     
            async moveLink(data){
                let links = this.customLinks;
                if(data.screen != 'custom')
                    links = this.serviceLinks;

                let old_index = data.original;
                let new_index = data.current;

                //If new index too high, set to max value
                if(new_index >= links.length)
                    new_index = links.length - 1;

                links.splice(new_index, 0, links.splice(old_index, 1)[0]);
            },       
            async moveDown(data){
                
                
                //Move secondary nav link down
                if(data.screen == 'custom'){
                    let links = this.customLinks;
                    if(data.i < links.length){
                        links.splice(data.i + 1, 0, links.splice(data.i, 1)[0])
                    }
                    await this.saveOrder(data.screen);
                }
                //Move service nav link down within group
                else{   
                    let links = this.serviceLinksInGroupOrder();

                    //Move the selected item

                    //Get the index of it
                    let index = -1;
                    for(var ii = 0; ii < links.length; ii++){
                        if(links[ii].id == data.id){
                            index = ii;
                            break;
                        }
                    }

                    if(index < links.length){
                        links.splice(index + 1, 0, links.splice(index, 1)[0])
                    }
                    //Save order
                    await this.saveServicesOrder(links);
                }
            },
            async moveUp(data){
                
                //Move secondary nav link up
                if(data.screen == 'custom'){
                    let links = this.customLinks;
                    if(data.i > 0){
                        links.splice(data.i - 1, 0, links.splice(data.i, 1)[0])
                    }
                    await this.saveOrder(data.screen);
                }
                //Move service nav link up within group
                else{   
                    let links = this.serviceLinksInGroupOrder();
                    
                    //Move the selected item
                    //Get the index of it
                    let index = -1;
                    for(var ii = 0; ii < links.length; ii++){

                        if(links[ii].id == data.id){
                            index = ii;
                            break;
                        }
                    }
                    if(index > 0){
                        links.splice(index - 1, 0, links.splice(index, 1)[0])
                    }

                    //Save order
                    await this.saveServicesOrder(links);
                }
            },
            /* Returns all service links in group, subgroup order */
            serviceLinksInGroupOrder(){
                    //Holds all service links in group order
                    let links = [];
                    
                    //Go through all groups, adding links in order
                    for(var ii = 0; ii < this.serviceGroups.length; ii++){
                        let groupLinks = this.serviceLinksByGroup(this.serviceGroups[ii].id);                        
                        for(var jj = 0; jj < groupLinks.length; jj++){
                            links.push(groupLinks[jj]);
                        }
                    }

                    //Add ungrouped links
                    let groupLinks = this.serviceLinksByGroup();           
                    for(var jj = 0; jj < groupLinks.length; jj++){
                        links.push(groupLinks[jj]);
                    }

                    //Add orphaned links             
                    for(var jj = 0; jj < this.orphanedServiceLinks.length; jj++){
                        links.push(this.orphanedServiceLinks[jj]);
                    }

                    return links;
            },
            async saveServicesOrder(links){
                let postUrl = 'links_page';

                let debug = [];

                //Build ordered ids of links
                let ids = [];
                for(var ii = 0; ii < links.length; ii++){
                    ids.push(links[ii].id)
                    debug.push(links[ii].title)
                }

                let data = {
                    data: {
                        attributes: {
                            order: ids
                        }
                    }
                }
                
                try{
                        console.log('pre-error: ', postUrl, data);
                        let resp = await this.$fapi.patch(postUrl, data); 
                        //link.editing = false;
                        this.loadLinks();
                    }catch(resp){
                        console.log('error resp: ', resp);
                        let errorDetail = resp.response.data.errors[0].detail;
                        let errorTitle = resp.response.data.errors[0].title;
                        if(errorDetail != undefined){
                            this.error = 'Error: ' + errorDetail;
                        }else if(errorTitle != undefined){
                            this.error = 'Error: ' + errorTitle;
                        }else{
                            this.error = 'Error: There was an issue saving link order';
                        }
                    }
            },
            async saveOrder(type){
                
                let postUrl = 'links';
                let links = this.customLinks;
                if(type != 'custom'){
                    postUrl = 'links_page';
                    links = this.serviceLinks;
                }

                let debug = [];
                //Build ordered ids of links
                let ids = [];
                for(var ii = 0; ii < links.length; ii++){
                    ids.push(links[ii].id)
                    debug.push(links[ii].title)
                }

                let data = {
                    data: {
                        attributes: {
                            order: ids
                        }
                    }
                }
                
                try{
                        console.log('pre-error: ', postUrl, data);
                        let resp = await this.$fapi.patch(postUrl, data); 
                        link.editing = false;
                    }catch(resp){
                        console.log('error resp: ', resp);
                        let errorDetail = resp.response.data.errors[0].detail;
                        let errorTitle = resp.response.data.errors[0].title;
                        if(errorDetail != undefined){
                            this.error = 'Error: ' + errorDetail;
                        }else if(errorTitle != undefined){
                            this.error = 'Error: ' + errorTitle;
                        }else{
                            this.error = 'Error: There was an issue saving link order';
                        }
                    }

            },
            async deleteLink(link){              

                try{
                    let resp = await this.$fapi.delete(link.links.self); 
                    link.deleting = false;
                    this.loadLinks();
                }catch(resp){
                    let errorDetail = resp.response.data.errors[0].detail;
                    let errorTitle = resp.response.data.errors[0].title;
                    if(errorDetail != undefined){
                        this.error = 'Error: ' + errorDetail;
                    }else if(errorTitle != undefined){
                        this.error = 'Error: ' + errorTitle;
                    }else{
                        this.error = 'Error: There was an issue saving the link';
                    }
                }
            },
            async editLink(link){   

                this.editMode = 'link';
                this.editingLink = link

            },
            async saveGroupTest(){

                let postUrl = 'links_page_groups';
                let data = {
                    "data": {
                        "attributes": {
                            title: 'Group Title',
                            icon: 'columns'
                        }
                    }
                }

                try{
                    let resp = await this.$fapi.post(postUrl, data); 
                    link.editing = false;
                }catch(resp){
                    let errorDetail = resp.response.data.errors[0].detail;
                    let errorTitle = resp.response.data.errors[0].title;
                    if(errorDetail != undefined){
                        this.error = 'Error: ' + errorDetail;
                    }else if(errorTitle != undefined){
                        this.error = 'Error: ' + errorTitle;
                    }else{
                        this.error = 'Error: There was an issue saving the link';
                    }
                }
            },
            async saveLinkChanges(link){
                
                let postUrl = 'links';
                if(link.type != 'CustomLinks')
                    postUrl = 'links_page';

                let data = {
                    "data": {
                        "attributes": {
                            title: link.attributes.title,
                            description: link.attributes.description,
                            icon: link.attributes.icon,
                            url: link.attributes.url,
                            style: link.attributes.style,
                            group: link.attributes.group,
                            size: parseInt(link.attributes.size),
                        }
                    }
                }

                //Saving new link
                if(link.new){
                    try{
                        let resp = await this.$fapi.post(postUrl, data); 
                        link.editing = false;
                        link.new = false;
                        link.links = {};
                        link.links.self = resp.data.data.links.self;
                    }catch(resp){
                        let errorDetail = resp.response.data.errors[0].detail;
                        let errorTitle = resp.response.data.errors[0].title;
                        if(errorDetail != undefined){
                            this.error = 'Error: ' + errorDetail;
                        }else if(errorTitle != undefined){
                            this.error = 'Error: ' + errorTitle;
                        }else{
                            this.error = 'Error: There was an issue saving the link';
                        }
                    }

                }
                //Editing existing link
                else{
                    
                    postUrl = link.links.self;

                    try{
                        let resp = await this.$fapi.patch(postUrl, data); 
                        link.editing = false;
                    }catch(resp){
                        let errorDetail = resp.response.data.errors[0].detail;
                        let errorTitle = resp.response.data.errors[0].title;
                        if(errorDetail != undefined){
                            this.error = 'Error: ' + errorDetail;
                        }else if(errorTitle != undefined){
                            this.error = 'Error: ' + errorTitle;
                        }else{
                            this.error = 'Error: There was an issue saving the link';
                        }
                    }
                }

            },  
            async loadLinkGroups(){
                
                //Load service link groups if enabled
                if(this.$store.state.fi.linksPageGroupsEnabled){
                    let links_page_groups = await this.$fapi.get('links_page_groups');
                    this.serviceGroups = links_page_groups['data']['data'];

                    
                    //Parse slideshow setting from name,
                    for(var ii = 0; ii < this.serviceGroups.length; ii++){

                        let g = this.serviceGroups[ii];
                        let split = g.attributes.title.split('__');
                        g.attributes.titleUse =  split[0];
                        if(split.length > 1 && split[1] == 'slide'){
                            g.slideshow = true;
                        }

                    }
                }
            },
            async loadLinks(){
                
                //Load service links if enabled
                if(this.$store.state.fi.linksPageEnabled){
                    let links_page = await this.$fapi.get('links_page');
                    this.serviceLinks = links_page['data']['data'];
                }

                //Load standard custom links
                let links = await this.$fapi.get('links');
                this.customLinks = links['data']['data'];
                
            }
        },
        setup(){
            

			/* Form field values */
			let fv = {}

            const addGroupSlideshow = ref(false);

            const editMode = ref('');
            const editingLink = ref({});
            const editingInfo = ref({});
            const confirmDeleteGroup = ref('');
            const editGroupId = ref('')
            const iconFilter = ref('');
            const showGroupEdit = ref(false);
            const showIconSelect = ref(false);
            const skinnyPreview = ref(false);
            const icons = ref([
                        'academic-cap',
            'adjustments-horizontal',
            'adjustments-vertical',
            'archive',
            'archive-down',
            'archive-x',
            'arrow-down',
            'arrow-down-circle',
            'arrow-down-left',
            'arrow-down-right',
            'arrow-down-square',
            'arrow-down-stack',
            'arrow-down-tray',
            'arrow-left',
            'arrow-left-circle',
            'arrow-left-rectangle',
            'arrow-long-down',
            'arrow-long-left',
            'arrow-long-right',
            'arrow-long-up',
            'arrow-right',
            'arrow-right-circle',
            'arrow-up',
            'arrow-up-circle',
            'arrow-up-left',
            'arrow-up-right',
            'arrow-up-square',
            'arrow-up-stack',
            'arrow-up-tray',
            'arrow-uturn',
            'arrows-in',
            'arrows-left-right',
            'arrows-out',
            'arrows-up-down',
            'at-symbol',
            'attach',
            'backspace',
            'backward',
            'ban',
            'banknotes',
            'battery-0',
            'battery-100',
            'battery-50',
            'beaker',
            'bell',
            'bell-alert',
            'bell-slash',
            'bell-snooze',
            'bolt',
            'bolt-slash',
            'book-open',
            'bookmark',
            'bookmark-slash',
            'bookmark-square',
            'briefcase',
            'bug',
            'building-library',
            'building-office',
            'building-office-2',
            'building-storefront',
            'cake',
            'calculator',
            'calendar',
            'calendar-dates',
            'camera',
            'chart-bar',
            'chart-bar-square',
            'chart-pie',
            'chat',
            'check',
            'check-badge',
            'check-circle',
            'circle-stack',
            'clipboard',
            'clipboard-document',
            'clipboard-document-check',
            'clipboard-document-list',
            'clock',
            'cloud',
            'cloud-arrow-down',
            'cloud-arrow-up',
            'code-bracket',
            'code-bracket-square',
            'cog',
            'cog-2',
            'cog-8-tooth',
            'columns',
            'cpu-chip',
            'credit',
            'cube',
            'cube-transparent',
            'cursor-rays',
            'cursor-ripple',
            'device-mobile',
            'device-tablet',
            'document',
            'document-chart-bar',
            'document-check',
            'document-download',
            'document-duplicate',
            'document-minus',
            'document-plus',
            'document-text',
            'document-upload',
            'document-zoom',
            'dollar',
            'ellipsis-circle',
            'ellipsis-horizontal',
            'ellipsis-vertical',
            'envelope',
            'envelope-open',
            'euro',
            'exclamation-circle',
            'exclamation-triangle',
            'export',
            'eye',
            'eye-dropper',
            'eye-slash',
            'face-frown',
            'face-smile',
            'film',
            'filter',
            'fingerprint',
            'fire',
            'flag',
            'folder',
            'folder-down',
            'folder-minus',
            'folder-open',
            'folder-plus',
            'forward',
            'gif',
            'gift',
            'gift-top',
            'globe',
            'hand-raised',
            'hashtag',
            'heart',
            'home',
            'home-modern',
            'identification',
            'inbox',
            'inbox-down',
            'inbox-stack',
            'info',
            'input',
            'key',
            'language',
            'light-bulb',
            'lines',
            'link',
            'list-bullet',
            'lock',
            'lock-open',
            'loop-round',
            'loop-square',
            'map',
            'map-pin',
            'megaphone',
            'microphone',
            'minus-circle',
            'moon',
            'musical-note',
            'newspaper',
            'paint-brush',
            'paper-airplane',
            'pause',
            'pause-circle',
            'pc',
            'pencil',
            'pencil-box',
            'phone',
            'phone-in',
            'phone-out',
            'phone-x',
            'photo',
            'play',
            'play-pause',
            'plus',
            'power',
            'presentation-chart-bar',
            'presentation-chart-line',
            'printer',
            'puzzle-piece',
            'qr-code',
            'question-circle',
            'queue-list',
            'radio',
            'receipt-percent',
            'rectangle-stack',
            'rocket-launch',
            'rss',
            'scale',
            'scissors',
            'search',
            'send',
            'server',
            'server-stack',
            'share',
            'shield-check',
            'shield-exclamation',
            'shopping-bag',
            'shopping-cart',
            'signal',
            'signal-slash',
            'sort-asc',
            'sort-desc',
            'sparkles',
            'speaker-wave',
            'speaker-x',
            'square-2-stack',
            'square-stack',
            'squares-2x2',
            'squares-plus',
            'star',
            'stop',
            'stop-circle',
            'sun',
            'support',
            'tag',
            'template',
            'theme',
            'thumb-down',
            'thumb-up',
            'ticket',
            'ticket-2',
            'transfer',
            'trash',
            'trend-down',
            'trend-up',
            'trophy',
            'truck',
            'tv',
            'user',
            'user-circle',
            'user-group',
            'user-minus',
            'user-plus',
            'users',
            'variable',
            'video-camera',
            'video-camera-slash',
            'viewfinder-circle',
            'wallet',
            'web',
            'wifi',
            'window',
            'wrench',
            'wrench-screwdriver',
            'x',
            'x-mark',
            'zoom-circle',
            'zoom-in',
            'zoom-out'
            ]);
       
            const customLinks = ref([]);        
            const addingGroup = ref(false);
            const addGroupName = ref('');
            const addGroupIcon = ref('web');

            const serviceLinks = ref([]);     
            const serviceGroups = ref([]); 
            const ungroupedServiceExpanded = ref(true);

            const eventsUrl = ref('https://bing.com');
            const frameHeightUpdate = ref(0)
            const frameHeight = computed({          
                get: () => {
                    frameHeightUpdate.value++;      
                        
                    if(window.innerWidth >= 768)                 
                        return `height: ${window.innerHeight-225}px;`;      
                    else 
                        return `height: ${window.innerHeight-132}px;`;           
                }
            });
            const titleRefs = {
                'custom': ref([]),
                'service': ref([])
            }
            const mainNav = ref(['','','']);

            return {  
                addGroupIcon,
                addGroupName,
                addGroupSlideshow,
                addingGroup,
                confirmDeleteGroup,
                customLinks,  
                editGroupId,
                editMode,
                editingInfo,
                editingLink,
                eventsUrl,            
                frameHeight,
                frameHeightUpdate, 
				fv,
                iconFilter,
                icons,
                mainNav,
                serviceGroups,
                serviceLinks,
                showGroupEdit,
                showIconSelect,
                skinnyPreview,
                titleRefs,
                ungroupedServiceExpanded
            }

        }
	}
	
</script>
