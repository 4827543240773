<template>
    <div class="w-full max-w-screen-lg mx-auto flex flex-col md:flex-row md:p-2 md:rounded-sm pb-5">

        <!--Sidebar, shows current loan values-->
        <div v-if="step < 8 && saveToEmailStatus == ''" class="w-full md:w-72 md:mr-2 n3-content md:rounded-sm leading-5 flex flex-col justify-start">
            <div @click="mobileSummaryExpanded = !mobileSummaryExpanded" class="border-b-2 w-full text-lg p-2 cursor-pointer md:cursor-default mn3-navbar md:rounded-t flex flex-row items-center justify-between bg-green-500">
                <span>Loan Application</span>
                <Icon :id="mobileSummaryExpanded ? 'minus' : 'plus'" class="block md:hidden h-6 w-6" />
            </div>
            <div  @click="mobileSummaryExpanded = true" v-if="!mobileSummaryExpanded" class="mn3-content-alt cursor-pointer flex md:hidden flex-row items-center justify-center uppercase text-xs py-1 mb-2">
                <Icon id="eye" class="h-4 w-4 mr-1" />View Current Application Info</div>
            <div :class="`w-full ${mobileSummaryExpanded ? 'flex' : 'hidden'} md:flex flex-col`">
            <!--Show get started text-->
            <div v-if="stepHighest == 1"
                class="italic p-2">
                To get started on your new loan, select the type you would like to apply for on the right.
            </div>
            <!--Show current loan info-->
            <div v-else class="flex flex-col text-sm">
                <!--S1 Values-->
                <div v-if="stepHighest > 1"
                    :class="sideHeaderClass"
                    @click="jumpToStep(3)">
                    <span class="pt-1">Basic Loan Info</span>
                </div>
                <div v-if="stepHighest > 1 && loanApp.type != ''"
                    @click="jumpToStep(1)"
                    :class="sideItemClass">
                    <span class="font-bold">Loan Type:</span>
                    <span>{{loanApp.type}}</span>
                </div>          
                <!--S2 Values-->      
                <div v-if="stepHighest > 2 && loanApp.newUsed != undefined"
                    @click="jumpToStep(2)"
                    :class="sideItemClass">
                    <span class="font-bold"></span>
                    <span>{{loanApp.newUsed}}</span>
                </div>
                <!--S3 Values-->      
                <div v-if="stepHighest > 3"
                    @click="jumpToStep(3, 'amount')"
                    :class="sideItemClass">
                    <span class="font-bold">Loan Amount:</span>
                    <span>{{formatMoney(loanApp.amount)}}</span>
                </div>
                <div v-if="stepHighest > 3"
                    @click="jumpToStep(3, 'term')"
                    :class="sideItemClass">
                    <span class="font-bold">Term:</span>
                    <span>{{loanApp.term}} Months</span>
                </div>
                <div v-if="stepHighest > 3"
                    @click="jumpToStep(3, 'freq')"
                    :class="sideItemClass">
                    <span class="font-bold">Frequency:</span>
                    <span>{{loanApp.freq}}</span>
                </div>
                <!--S4 Values-->
                <div v-if="stepHighest > 4" class="flex flex-col w-full mt-3">
                    <div v-if="loanApp.cosignerUsed === true" 
                        :class="`${loanApp.cosignerUsed ? 'justify-between' : ''} p-2 flex flex-row`">

                        <button @click="showCosigner(false)"
                            :class="`${cosignerShow ? 'opacity-50' : ''} w-1/2 mn3-button border p-1 rounded-l-sm flex flex-row justify-start`">
                            <Icon id="user-solid" class="h-4 w-4 mr-1" /><span>Applicant</span>
                        </button>

                        <button 
                            @click="showCosigner(true) "
                            :class="`${!cosignerShow ? 'opacity-50' : ''} w-1/2 mn3-button border p-1 rounded-r-sm flex flex-row justify-start`">
                             <Icon id="users" class="h-4 w-4 mr-1" /><span>Co-Signer</span>
                        </button>
                            
                    </div> 
                    <!--Add co-signer, displayed if no cosigner added by review-->
                    <div v-else-if="stepHighest == 8">
                        <button @click="selectAddCosigner(true, false)"
                            :class="`w-3/2 mx-auto mn3-button border p-1 rounded-sm flex flex-row justify-start mb-1`">
                            <Icon id="users" class="h-4 w-4 mr-1" /><span>Add Co-Signer</span>
                        </button>
                    </div> 
                    
                    <div :class="`mt-0 ${sideHeaderClass} 
                        ${loanApp.cosignerUsed ? 'justify-between' : ''} 
                        ${stepHighest > 4 ? 'cursor-pointer' : ''} flex flex-row`"
                        @click="jumpToStep(4)">
                        <span class="pt-1">{{cosignerShow ? 'Co-Signer' : 'Applicant'}} Info</span>
                    </div>       
                    <div v-if="summaryUser.name != undefined"  :class="sideItemClass"
                        @click="jumpToStep(4, 'name')">
                        <span class="font-bold">Name:</span>
                        <span>{{summaryUser.name}}</span>
                    </div>              
                    <div v-if="summaryUser.email != undefined"  :class="sideItemClass"
                        @click="jumpToStep(4, 'email')">
                        <span class="font-bold">Email:</span>
                        <span>{{summaryUser.email}}</span>
                    </div>              
                    <div v-if="summaryUser.phoneHome != undefined"  :class="sideItemClass"
                        @click="jumpToStep(4, 'phone')">
                        <span class="font-bold">Phone:</span>
                        <span>{{summaryUser.phoneHome}}</span>
                    </div>              
                    <div v-if="summaryUser.phoneWork != undefined"
                        :class="sideItemClass"
                        @click="jumpToStep(4, 'phoneWork')">
                        <span class="font-bold">Work Phone:</span>
                        <span>{{summaryUser.phoneWork}}</span>
                    </div>              
                    <div v-if="summaryUser.bday != undefined"  :class="sideItemClass"
                        @click="jumpToStep(4, 'bday')">
                        <span class="font-bold">Bday:</span>
                        <span>{{summaryUser.bday}}</span>
                    </div>              
                    <div v-if="summaryUser.ssn != undefined" 
                        :class="sideItemClass"
                        @click="jumpToStep(4, 'ssn')">
                        <span class="font-bold">SSN:</span>
                        <span>***-**-{{summaryUser.ssn.substring(7)}}</span>
                    </div>              
                    <div v-if="summaryUser.citizen != undefined" 
                         :class="sideItemClass"
                        @click="jumpToStep(4, 'citizen')">
                        <span class="font-bold">US Citizen:</span>
                        <span>{{summaryUser.citizen}}</span>
                    </div>
                </div>               
                <!--S5 Values-->
                <div v-if="stepHighest > 5" class="flex flex-col w-full">
                    <div :class="`${sideHeaderClass}
                        ${stepHighest > 5 ? 'cursor-pointer' : ''} mt-3`"
                        @click="jumpToStep(5)">
                        <span class="pt-1">{{cosignerShow ? 'Co-Signer' : 'Applicant'}} Address</span>
                    </div>         
                    <div class="flex flex-col items-end my-1 px-2 cursor-pointer">                        
                        <span @click="jumpToStep(5, 'addr1')" v-if="summaryUser.addr1 != undefined">{{summaryUser.addr1}}</span>
                        <span @click="jumpToStep(5, 'addr2')" v-if="summaryUser.addr2 != undefined">{{summaryUser.addr2}}</span>
                        <span @click="jumpToStep(5, 'city')" v-if="summaryUser.city != undefined">{{summaryUser.city}}, {{summaryUser.state}} {{summaryUser.zip}}</span>
                    </div>                    
                    <div :class="sideItemClass"
                        @click="jumpToStep(5, 'rent')">
                        <span class="font-bold">Mortgage/Rent:</span>
                        <span>{{formatMoney(summaryUser.addrRent)}}</span>
                    </div>                     
                    <div :class="sideItemClass"
                        @click="jumpToStep(5, 'addrSince')">
                        <span class="font-bold">Address Since:</span>
                        <span>{{summaryUser.addrSince}}</span>
                    </div>       
                </div>            
                <!--S6 Values-->
                <div v-if="stepHighest > 6" class="flex flex-col w-full">
                    <div :class="`${sideHeaderClass} 
                        ${stepHighest > 6 ? 'cursor-pointer' : ''} mt-3`"
                        @click="jumpToStep(6)">
                         <span class="pt-1">{{cosignerShow ? 'Co-Signer' : 'Applicant'}} Finances</span>
                    </div>                        
                    <div :class="sideItemClass"
                        @click="jumpToStep(6, 'emp')">
                        <span class="font-bold">Employer:</span>
                        <span>{{summaryUser.emp}}</span>
                    </div>                      
                    <div :class="sideItemClass"
                        @click="jumpToStep(6, 'empPos')">
                        <span class="font-bold">Position:</span>
                        <span>{{summaryUser.empPos}}</span>
                    </div>                          
                    <div :class="sideItemClass"
                        @click="jumpToStep(6, 'empIncome')">
                        <span class="font-bold">Income:</span>
                        <span>{{formatMoney(summaryUser.empIncome)}}</span>
                    </div>                          
                    <div :class="sideItemClass"
                        @click="jumpToStep(6, 'empSince')">
                        <span class="font-bold">Since:</span>
                        <span>{{summaryUser.empSince}}</span>
                    </div>           
                </div> 
            </div>
            </div>
                <div class="flex-grow" />
                <!--Continue Later box-->
                <div v-if="false && stepHighest > 3 && stepHighest < 9" class="flex flex-col w-full p-2 md:border-t mb-3 md:mb-2 md:mt-5">
                    <span class="text-xs tracking-tight text-center">Need to finish your application later?</span>
                    <button @click="saveToEmailStatus = 'show'"
                        class="w-full rounded-sm mn3-submit border p-2 uppercase text-center font-bold text-lg">Save to Email</button>
                </div><!--
            <div  @click="mobileSummaryExpanded = false" v-if="mobileSummaryExpanded" class="mn3-content-alt cursor-pointer flex md:hidden flex-row items-center justify-center uppercase text-xs py-1 mb-2">
                <Icon id="eye" class="h-4 w-4 mr-1" />Collapse Application Info</div>-->
        </div>

        <div class="flex-srhink-0 w-full flex flex-col mn3-content p-2 md:rounded-sm pb-5">
        <!--Header / Progress-->
        <div class="w-full flex flex-col">
            <div class="flex flex-row">
                <Icon id="loan" class="h-8 w-8 mr-2" />
                <span v-if="cosignerMode" class="uppercase text-2xl">{{stepTitle[step].replace('Applicant', 'Co-Signer')}}</span>
                <span v-else class="uppercase text-2xl">{{stepTitle[step]}}</span>
            </div>
            <div class="w-full mn3-content-alt h-4 my-2 rounded-sm">
                <div :style="`width: ${stepToProgress[stepHighest]}%`"
                    class="bg-gray-800 h-4 duration-150 rounded-sm text-xs text-gray-50 text-right pr-1 tracking-tighter">
                <span v-if="stepHighest > 2">{{stepToProgress[stepHighest]}}% Complete</span>
                </div>
            </div>
        </div>

        <div v-if="alert != ''"
            class="w-full mn3-alert rounded-sm text-center p-2 flex flex-row items-center justify-center">
            <Icon id="exclamation" class="h-6 w-6 mr-2" />
            <span>{{alert}}</span>
        </div>

        <transition name="fade-quick" mode="out-in">
        <!--Show save to email-->
        <div v-if="saveToEmailStatus == 'show'"
            class="w-3/4 mx-auto flex flex-col">
            <span class="w-full text-center text-3xl my-4">Save Your Progress</span>
            <span>Enter your email address to receieve a link to pick up your application exactly where you left off.</span>
            <div class="w-full flex flex-row items-center">
                <Icon @click="refSaveEmail.selectField()" id="mail" class="h-10 w-10 opacity-60"/>
                <Field  
                    ref="refSaveEmail"
                    label="Email Address"                                 
                    type="text" 
                    :class="`w-full`"
                    :formValue="fv.saveEmail" />
            </div>
            <div class="w-full flex flex-row">
                <button @click="saveToEmail()"
                    class="mn3-submit w-3/4 mn3-button border p-2 rounded-l-sm uppercase font-bold">Save Progress</button>
                <button @click="saveToEmailStatus = ''"
                    class="mn3-cancel w-1/4 mn3-button border p-2 rounded-r-sm uppercase font-bold">Cancel</button>
            </div>
        </div>
        <!--Status Saved-->
        <div v-else-if="saveToEmailStatus == 'saved'"
            class="w-3/4 mx-auto flex flex-col">
            <span class="w-full text-center text-3xl my-4">Progress Saved</span>
            <span>Your progress has been saved for the next 72 hours!</span>
            <span class="my-2 mn3-highlight p-2 rounded-sm rounded-sm text-center">http://link-to-resume</span>
            <span class="">Use the link above to resume your application, this has also been sent to your email address.</span>
            <div class="w-full flex flex-row mt-5">
                <button @click="$router.push('/')"
                    class="mn3-submit w-full mn3-button border p-2 rounded-l-sm uppercase font-bold">Exit</button>
            </div>
        </div>
        <!--S1 - Loan type select-->
        <div v-else-if="step == 1" 
            class="w-full flex flex-col">
            <span class="w-full text-center text-3xl my-4">What type of loan are you applying for?</span>
            <div class="w-full flex flex-row justify-around">
                <div v-for="type in loanTypesMain" :key="type.label"
                    @click="selectLoanType(type.label)"
                    class="cursor-pointer flex-grow-0 flex-shrink-0 p-4 w-1/5 rounded-sm mn3-button border flex flex-col items-center">
                    <Icon :id="type.icon" class="h-14 w-14" />
                    <span class="uppercase font-bold tracking-tight">{{type.label}}</span>
                </div>
            </div>
        </div>
        <!--S2 Loan Type Detail-->
        <div v-else-if="step == 2"
            class="flex flex-col w-ful">   
            <span class="w-full text-center text-3xl my-4">What type of loan are you applying for?</span>
            <div class="w-full flex flex-row justify-around flex-wrap">
                <div v-for="type in loanTypesFull[loanApp.type]" :key="type.icon"
                        @click="selectNewUsed(type.label)"
                        class="cursor-pointer flex-grow-0 flex-shrink-0 p-4 w-1/5 m-2 rounded-sm mn3-button border flex flex-col items-center justify-center">
                        <span class="uppercase font-bold tracking-tight text-center leading-4 p-1">{{type.label}}</span>
                    </div>
            </div>
        </div>
        <!--S3 - Basic Loan Info-->
        <div v-else-if="step == 3"
            class="flex flex-row flex-wrap">
            <!--New/Used if an auto/recreational-->
            <div class="w-full flex flex-row items-center">
                <Icon @click="refAmount.selectField()" id="dollar" class="h-10 w-10 opacity-60"/>
                <Field  
                    ref="refAmount"
                    label="Loan Amount"                                 
                    type="text" 
                    textFormat="amount"
                    :class="`w-full`"
                    :formValue="fv.tempLoanAmount" />
            </div>
            <div class="w-1/2 flex flex-row items-center">
                <Icon @click="refTerm.selectField()" id="calendar" class="h-10 w-10 opacity-60"/>
                <Field  
                    ref="refTerm"
                    label="Term (Months)"                                 
                    type="text" 
                    textFormat="number"
                    :class="`w-full`"
                    :formValue="fv.tempTerm" />
            </div>
            <div class="w-1/2 flex flex-row items-center">
                <Icon @click="refFreq.selectField()" id="calendar" class="h-10 w-10 opacity-60"/>
                <Field  
                    ref="refFreq"
                    label="Frequency"                                 
                    type="select" 
                    :options="loanFreqs"
                    :class="`w-full`"
                    :formValue="fv.tempFreq" />
            </div>
        </div>

        <!--S4 - Basic User Info-->
        <div v-else-if="step == 4"
            class="flex flex-row flex-wrap">
            <div class="w-full flex flex-row items-center">
                    <Icon @click="refHasAccount.selectField()" id="library-solid" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refHasAccount"
                        label="Are you a current account holder of the Credit Union?"                                 
                        type="select" 
                        @change="checkClearAccountId()"
                        :class="`w-full`"
                        :formValue="fv.tempHasAccount"
                        :options="[
                        {
                            value: 'Yes',
                            label: 'Yes'
                        },
                        {
                            value: 'No',
                            label: 'No'
                        }]" />
            </div>
            <div :class="`${fv.tempHasAccount.value != 'Yes' && (fv.tempAccountId.value == undefined || fv.tempAccountId.value.length == 0)? 'hidden' : ''} w-full flex flex-row items-center`">
                    <Icon @click="refAccountId.selectField()" id="hash" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refAccountId"
                        label="Account Number"                                 
                        type="text" 
                        :class="`w-full`"
                        :formValue="fv.tempAccountId" />
            </div>
            <div class="w-full flex flex-row items-center">
                    <Icon @click="refName.selectField()" id="user-solid" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refName"
                        label="Applicant Name"                                 
                        type="text" 
                        :class="`w-full`"
                        :formValue="fv.tempName" />
            </div>
            <div class="w-full flex flex-row items-center">
                    <Icon @click="refEmail.selectField()"  id="mail" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refEmail"
                        label="Applicant Email"                                 
                        type="text" 
                        :class="`w-full`"
                        :formValue="fv.tempEmail" />
            </div>
            <div class="w-1/2 flex flex-row items-center">
                    <Icon @click="refPhone.selectField()" id="mobile" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refPhone"
                        label="Main Phone"                                 
                        type="text" 
                        textFormat="phone"
                        :class="`w-full`"
                        :formValue="fv.tempPhoneHome" />
            </div>
            <div class="w-1/2 flex flex-row items-center">
                    <Icon @click="refPhoneWork.selectField()" id="phone" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refPhoneWork"
                        label="Work Phone (Optional)"                                 
                        type="text" 
                        textFormat="phone"
                        :class="`w-full`"
                        :formValue="fv.tempPhoneWork" />
            </div>
            <div class="w-1/2 flex flex-row items-center">
                    <Icon @click="refBday.selectField()" id="cake-solid" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refBday"
                        label="Birthdate"                                 
                        type="date" 
                        :class="`w-full mb-6`"
                        :formValue="fv.tempBday" />
            </div>
            <div class="w-1/2 flex flex-row items-center">
                    <Icon @click="refSsn.selectField()" id="id" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refSsn"
                        label="Social Security #"                                 
                        type="text" 
                        textFormat="ssn"
                        :class="`w-full`"
                        desc="Required to run a credit check."
                        :formValue="fv.tempSsn" />
            </div>
            <div class="w-full flex flex-row items-center">
                    <Icon @click="refCitizen.selectField()" id="library-solid" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refCitizen"
                        label="US Citizen"                                 
                        type="select" 
                        :class="`w-full`"
                        :formValue="fv.tempCitizen"
                        :options="[
                        {
                            value: 'Yes',
                            label: 'Yes'
                        },
                        {
                            value: 'No',
                            label: 'No'
                        }]" />
            </div>
        </div>

        <!--S5 - User Addr Info-->
        <div v-else-if="step == 5"
            class="flex flex-row flex-wrap">
            <div class="w-full flex flex-row items-center">
                    <Icon @click="refAddr1.selectField()" id="home" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refAddr1"
                        label="Address Line 1"                                 
                        type="text" 
                        :class="`w-full`"
                        :formValue="fv.tempAddr1" />
            </div>            
            <div class="w-full flex flex-row items-center">
                    <Icon @click="refAddr2.selectField()" id="home" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refAddr2"
                        label="Address Line 2 (Optional)"                                 
                        type="text" 
                        :class="`w-full`"
                        :formValue="fv.tempAddr2" />
            </div>         
            <div class="w-1/3 flex flex-row items-center">
                    <Icon @click="refCity.selectField()" id="home" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refCity"
                        label="City"                                 
                        type="text" 
                        :class="`w-full`"
                        :formValue="fv.tempCity" />
            </div>    
            <div class="w-1/3 flex flex-row items-center">
                    <Icon id="dollar" class="hidden h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refState"
                        label="State"                                 
                        type="text" 
                        :maxlength="2"
                        :class="`w-full uppercase`"
                        :formValue="fv.tempState" />
            </div>
            <div class="w-1/3 flex flex-row items-center">
                    <Icon id="dollar" class="hidden h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refZip"
                        label="Zip"                                 
                        type="text" 
                        textFormat="number"
                        :maxlength="5"
                        :class="`w-full uppercase`"
                        :formValue="fv.tempZip" />
            </div>            
            <div class="w-1/2 flex flex-row items-center">
                    <Icon @click="refRent.selectField()" id="dollar" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refRent"
                        label="Mortgage/Rent"                                 
                        type="text"
                        textFormat="amount" 
                        :class="`w-full`"
                        :formValue="fv.tempAddrRent" />
            </div>       
            <div class="w-1/2 flex flex-row items-center">
                    <Icon @click="refAddrSince.selectField()" id="calendar" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refAddrSince"
                        label="Current Address Since"                                 
                        type="date" 
                        :class="`w-full`"
                        :formValue="fv.tempAddrSince" />
            </div>  
        </div>

        <!--S6 - User Income Info-->
        <div v-else-if="step == 6"
            class="flex flex-row flex-wrap">
            <div class="w-full flex flex-row items-center">
                    <Icon @click="refEmp.selectField()" id="office-solid" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refEmp"
                        label="Employer"                                 
                        type="text" 
                        :class="`w-full`"
                        :formValue="fv.tempEmp" />
            </div>               
            <div class="w-full flex flex-row items-center">
                    <Icon @click="refEmpPos.selectField()" id="briefcase-solid" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refEmpPos"
                        label="Position"                                 
                        type="text" 
                        :class="`w-full`"
                        :formValue="fv.tempEmpPos" />
            </div>           
            <div class="w-1/2 flex flex-row items-center">
                    <Icon @click="refEmpIncome.selectField()" id="dollar" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refEmpIncome"
                        label="Income"                                 
                        type="text" 
                        textFormat="amount"
                        :class="`w-full`"
                        :formValue="fv.tempEmpIncome" />
            </div>               
            <div class="w-1/2 flex flex-row items-center">
                    <Icon @click="refEmpSince.selectField()" id="calendar" class="h-10 w-10 opacity-60"/>
                    <Field  
                        ref="refEmpSince"
                        label="Date Since"                                 
                        type="date" 
                        :class="`w-full`"
                        :formValue="fv.tempEmpSince" />
            </div>       
        </div>

        <!--S7 - Check for co-signer-->
        <div v-else-if="step == 7"
            class="flex flex-col w-ful">            
            <span class="w-full text-center text-3xl my-4">Do you want to add a co-signer?</span>
            <div class="w-full flex flex-row justify-around">
                <div v-for="type in [
                        {
                            icon: 'users',
                            label: 'Yes',
                            value: true
                        },{
                            icon: 'no',
                            label: 'No',
                            value: false
                        },
                    ]" :key="type.icon"
                        @click="selectAddCosigner(type.value, true)"
                        class="cursor-pointer flex-grow-0 flex-shrink-0 p-4 w-1/5 rounded-sm mn3-button border flex flex-col items-center">
                        <Icon :id="type.icon" class="h-14 w-14" />
                        <span class="uppercase font-bold tracking-tight">{{type.label}}</span>
                    </div>
            </div>
        </div>
        <!--S8 - Review-->
        <div v-else-if="step == 8"
            class="flex flex-col w-full"> 
            <span class="w-full text-center text-3xl my-4 leading-7">Review this information to make sure everything is correct.</span>
            <div class="w-3/4 mx-auto mb-5 text-sm leading-5 mn3-content-alt p-2 rounded-sm flex flex-row items-center">
                <Icon id="check" class="h-12 w-12 mr-2 flex-shrink-0" />
                <span>Click on any items if you need to make adjustments, use the button at the bottom to submit your loan application once you have verified that all information is correct.</span>
            </div>
            <!--Basic Loan Info-->
            <div class="flex flex-col border w-full md:w-3/4 mx-auto my-2">
                    <div class="w-full mn3-content-alt p-2 uppercase justify-start flex flex-row"><Icon id="loan" class="h-6 w-6 mr-1" /><span>Basic Loan Information</span></div>
                <!--<div @click="jumpToStep(1)" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Loan Type:</span><span class="text-right w-1/2">{{loanApp.type}}</span></div>-->
                <div v-if="loanApp.newUsed != undefined" @click="jumpToStep(2)" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Loan Type:</span><span class="text-right w-1/2">{{loanApp.newUsed}}</span></div>
                <div @click="jumpToStep(3, 'amount')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Loan Amount:</span><span class="text-right w-1/2">{{formatMoney(loanApp.amount)}}</span></div>
                <div @click="jumpToStep(3, 'term')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Term:</span><span class="text-right w-1/2">{{loanApp.term}} Months</span></div>
                <div @click="jumpToStep(3, 'freq')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Frequency:</span><span class="text-right w-1/2">{{loanApp.freq}}</span></div>
            </div>

            <!--Show add-cosigner button if none added-->
            <button v-if="!loanApp.cosignerUsed" @click="selectAddCosigner(true, false)"
                :class="`w-2/3 mx-auto mn3-button border p-1 rounded-sm flex flex-row justify-center mb-1`">
                <Icon id="users" class="h-4 w-4 mr-1" /><span>Add Co-Signer</span>
            </button>

            <!--Applicant / Cosigner Info-->
            <div :class="`${loanApp.cosignerUsed ? 'w-full' : 'w-full md:w-3/4 mx-auto'} flex flex-row`">
                <div v-for="apc in (loanApp.cosignerUsed ? [{type: 'Applicant', info: loanApp.applicant}, {type: 'Cosigner', info: loanApp.cosigner}] : [{type: 'Applicant', info: loanApp.applicant}])" :key="apc.info.name"
                    :class="`${loanApp.cosignerUsed ? 'w-2/5' : 'w-full'} flex flex-col border mx-auto my-2 `">
                    <div class="w-full mn3-content-alt p-2 uppercase justify-start flex flex-row"><Icon id="user-solid" class="h-6 w-6 mr-1" /><span>{{apc.type}} Information</span><div class="flex-grow" /><div v-if="apc.type == 'Cosigner'" @click="cosignerConfirmRemove = true" title="Remove Cosigner" class="cursor-pointer px-2 py-0 rounded-sm mn3-cancel">X</div></div>
                    <div v-if="cosignerConfirmRemove && apc.type == 'Cosigner'"
                        class="flex flex-col mn3-alert p-2 rounded-sm m-1">
                        <div>Are you sure you want to remove the co-signer from the application?</div>
                        <div class="w-full flex flex-row justify-between">

                            <Button 
                            class="flex-grow"
                            size="large"
                            type="submit"
                            @click="removeCosigner()"
                            label="Remove Co-Signer" />
                        <Button 
                            size="large"
                            type="cancel"
                            class="ml-1"
                            @click="cosignerConfirmRemove = false"
                            label="Cancel" />
                        </div>


                    </div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(4, 'name')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Name:</span><span class="text-right w-1/2">{{apc.info.name}}</span></div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(4, 'accountId')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Account ID:</span><span class="text-right w-1/2">{{(apc.info.accountId == undefined || apc.info.accountId.length == 0) ? 'NA' : apc.info.accountId}}</span></div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(4, 'email')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Email:</span><span class="text-right w-1/2">{{apc.info.email}}</span></div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(4, 'phone')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Phone:</span><span class="text-right w-1/2">{{apc.info.phoneHome}}</span></div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(4, 'phoneWork')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Work Phone:</span><span class="text-right w-1/2">{{apc.info.phoneWork}}</span></div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(4, 'bday')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Birthdate:</span><span class="text-right w-1/2">{{apc.info.bday}}</span></div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(4, 'ssn')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Social Security #:</span><span v-if="apc.info.ssn != undefined"  class="text-right w-1/2">***-**-{{apc.info.ssn.substring(7)}}</span></div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(4, 'citizen')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">US Citizen:</span><span class="text-right w-1/2">{{apc.info.citizen}}</span></div>
                    
                    <div class="w-full mn3-content-alt p-1 text-sm uppercase">{{apc.type}} Address</div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(5, 'addr1')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Address Line 1:</span><span class="text-right w-1/2">{{apc.info.addr1}}</span></div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(5, 'addr2')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Address Line 2:</span><span class="text-right w-1/2">{{apc.info.addr2}}</span></div>
                    <div class="w-full flex flex-row justify-between">
                        <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(5, 'city')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="font-bold mr-4">City:</span><span class="text-left">{{apc.info.city}}</span></div>
                        <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(5, 'state')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="font-bold mr-4">State:</span><span class="text-left">{{apc.info.state}}</span></div>
                        <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(5, 'zip')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="font-bold mr-4">zip:</span><span class="text-left">{{apc.info.zip}}</span></div>
                    </div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(5, 'addrRent')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Mortgage/Rent:</span><span class="text-right w-1/2">{{formatMoney(apc.info.addrRent)}}</span></div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(5, 'addrSince')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Address Since:</span><span class="text-right w-1/2">{{apc.info.addrSince}}</span></div>
                    
                    <div class="w-full mn3-content-alt p-1 text-sm uppercase">{{apc.type}} Finances</div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(6, 'emp')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Employer:</span><span class="text-right w-1/2">{{apc.info.emp}}</span></div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(6, 'empPos')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Position:</span><span class="text-right w-1/2">{{apc.info.empPos}}</span></div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(6, 'empIncome')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Income:</span><span class="text-right w-1/2">{{formatMoney(apc.info.empIncome)}}</span></div>
                    <div @click="showCosigner(apc.type == 'Cosigner');jumpToStep(6, 'empSince')" class="mn3-content-hover flex flex-row cursor-pointer p-1"><span class="w-1/2 font-bold">Since:</span><span class="text-right w-1/2">{{apc.info.empSince}}</span></div>
                </div>date
            </div>

            <div class="w-full md:w-3/4 mx-auto flex flex-row items-centerb">
                <label for="reviewed" class="normal-label w-full flex justify-start mn3-content-hover items-center my-3 cursor-pointer opacity-80 hover:opacity-100 border rounded-sm p-2 box-border">
                    <input v-model="accept" type="checkbox" class="w-6 h-6" id="reviewed" />
                    <div class="ml-5" for="reviewed">All of the above information is correct</div>
                </label>
            </div>  
            <button @click="nextStep()"
                class="mn3-submit mn3-button border p-2 w-full md:w-3/4 mx-auto rounded-sm text-xl uppercase">Submit Application</button>
        </div>
        <!--Complete screen-->        
        <div v-else-if="step == 9"
            class="flex flex-col w-full">             
            <span class="w-full text-center text-3xl my-4 leading-7">You loan application has been successfully submit!</span>
            <div class="w-3/4 mx-auto mb-5 text-sm leading-5 mn3-content-alt p-2 rounded-sm flex flex-row items-center">
                <Icon id="clock" class="h-12 w-12 mr-2 flex-shrink-0" />
                <span>A representative will be in contact about the status of your loan once your application has been reviewed.</span>
            </div>
        </div>
        </transition>
        <transition name="fade" mode="out-in">        
            <div v-if="step <= 2" class="w-full flex flex-col mt-5 p-2 border mn3-content">
                <span class="w-full text-sm">(Optional) Don't see what you're looking for above? Check all of the loan types we offer:</span>
                <select v-model="loanTypeDirect" class="mn3-input border w-full p-1 rounded-sm">
                    <option v-for="l in loanTypesFullCombined" :key="l.id" :value="l.id">{{l.label}}</option>
                </select>
            </div>
        </transition>
        <transition name="fade" mode="out-in">
        <div v-if="(step == 2 && loanTypeDirect != -1) || (step > 2 && step < 7 && saveToEmailStatus == '')" class="flex flex-row w-full justify-end">
            <button @click="nextStep()"
                class="mn3-submit mn3-button border p-2 rounded-sm uppercase font-bold w-full">{{stepHighest == 8 ? 'Return To Review' : 'Continue'}}</button>
        </div> 
        </transition>
        </div>


    </div>
    <!--
    [step: {{step}}]<br /><br />
    [{{refAmount}}]<br /><br />
    [{{loanApp}}]<br /><br />
    [{{fv}}]-->
</template>

<script>

    import { ref } from 'vue'

    import Button from '@/components/Button.vue'
    import Form from '@/components/Form.vue'
    import Icon from '@/components/Icon.vue'
    import Field from '@/components/Field.vue'

    export default {
      name: 'LoanApp',
      components: {
          Button,
          Form,
          Field,
          Icon
      },
      computed:{
        /* Returns categorized list of loan types (id, label) for the FI */
        loanTypesFull(){

            let loanTypes = {};
            let id = this.$store.state.fi.id
            
            switch(id){
                default:
                    loanTypes = { Vehicle: [], Home: [], Personal: [ {id: 0, label: 'Not Set Up'}], Recreational: [] };
                    break;

                //MN3ENC
                case '2':
                loanTypes =  {
                    Vehicle: [
                        { id: 2, label: 'New Cars' },
                        { id: 3, label: 'Used Cars' },
                        { id: 300, label: 'Vehical' }
                    ],
                    Home: [
                        { id: 7, label: 'Remodification' }, 
                        { id: 100, label: 'Home Equity' }
                    ],
                    Personal: [
                        { id: 1, label: 'Signature Loan' },                
                        { id: 4, label: 'Christmas Loan' },
                        { id: 5, label: 'Share Security Loan' }, 
                        { id: 6, label: 'Back to School' }, 
                        { id: 9, label: 'Short Term' }
                    ],
                    Recreational: [         
                        { id: 200, label: 'Recreational' }          
                    ]
                };
                break;

                //Decatur Postal
                case '14':
                loanTypes =  {
                    Vehicle: [
                        { id: 2, label: 'New Car' },
                        { id: 3, label: 'Used Car' }
                    ],
                    Home: [
                    ],
                    Personal: [
                        { id: 1, label: 'Share Secured' },   
                        { id: 4, label: 'Signature' },   
                        { id: 5, label: 'Christmas Loan' }
                    ],
                    Recreational: [         
                        { id: 6, label: 'New Rec Vehicle' },
                        { id: 7, label: 'Used Rec Vehicle' }         
                    ]
                };
                break;

                //SPINE
                case '16':
                loanTypes =  {
                    Vehicle: [
                        { id: 93, label: 'New Auto' },
                        { id: 94, label: 'Used Auto' },
                        { id: 89, label: 'Motorcycle' },
                        { id: 86, label: 'Tractor' }
                    ],
                    Home: [],
                    Personal: [
                        { id: 52, label: 'Personal Line of Credit' },
                        { id: 84, label: 'Furniture / Appliance' },
                        { id: 90, label: 'Share Assignment' },
                        { id: 91, label: 'Signature' }
                    ],
                    Recreational: [
                        { id: 85, label: 'Boat / Motor Home' },
                        { id: 83, label: 'Golf Cart' },
                        { id: 87, label: 'Camper' },
                        { id: 88, label: '4 Wheeler' },
                        { id: 92, label: 'Watercraft / Jet Ski' },
                        { id: 80, label: 'Trailer' },
                        { id: 95, label: 'Lawn & Garden Equipment' }
                    ]
                };
                break;

                //PAAC
                case '17':
                loanTypes =  {
                    Vehicle: [
                        { id: 1, label: 'New Auto' },
                        { id: 2, label: 'Used Auto' }
                    ],
                    Home: [
                        { id: 5, label: '2nd Mortage' }
                    ],
                    Personal: [
                        { id: 3, label: 'Signature' },
                        { id: 4, label: 'Share Secured' },                
                        { id: 6, label: 'Christmas' },
                        { id: 7, label: 'Fresh Start' }
                    ],
                    Recreational: [                    
                        { id: 8, label: 'Vacation'}
                    ]
                };
                break;

                //CTAS
                case '18':
                loanTypes =  {
                    Vehicle: [
                        { id: 2, label: 'New Cars' },
                        { id: 3, label: 'Used Cars' },
                        { id: 300, label: 'Vehical' }
                    ],
                    Home: [
                        { id: 7, label: 'Remodification' }, 
                        { id: 100, label: 'Home Equity' }
                    ],
                    Personal: [
                        { id: 1, label: 'Signature Loan' },                
                        { id: 4, label: 'Christmas Loan' },
                        { id: 5, label: 'Share Security Loan' }, 
                        { id: 6, label: 'Back to School' }, 
                        { id: 9, label: 'Short Term' }
                    ],
                    Recreational: [         
                        { id: 200, label: 'Recreational' }          
                    ]
                };

                //TSRFCU
                case '20':
                loanTypes = {
                    Vehicle: [
                        { id: 1, label: 'Automobiles' },
                        { id: 14, label: 'Used Automobiles' }
                    ],
                    Home: [
                    ],
                    Personal: [
                        { id: 16, label: 'Personal' },                        
                        { id: 17, label: 'Shared Secured' }
                    ],
                    Recreational: [               
                    ]
                };
                break;
                
                //CASFCU
                case '21':
                loanTypes = {
                    Vehicle: [
                        { id: 1, label: 'Automobiles' },
                        { id: 14, label: 'Used Automobiles' }
                    ],
                    Home: [
                    ],
                    Personal: [
                        { id: 16, label: 'Personal' },                        
                        { id: 17, label: 'Shared Secured' }
                    ],
                    Recreational: [               
                    ]
                };
                break;

                //NGM
                case '22':
                loanTypes =  {
                    Vehicle: [
                        { id: 1, label: 'New Auto' },
                        { id: 2, label: 'Used Auto' },
                        { id: 5, label: 'Motorcycle' },
                        { id: 7, label: 'Tractor' }
                    ],
                    Home: [
                    ],
                    Personal: [
                        { id: 8, label: 'Share Secured' },  
                        { id: 9, label: 'Unsecured' }
                    ],
                    Recreational: [         
                        { id: 3, label: 'Camper/Trailer' },
                        { id: 4, label: 'Boat' },
                        { id: 6, label: 'ATV/Snowmobile' }          
                    ]
                };
                

                //Metro Church
                case '23':
                loanTypes =  {
                    Vehicle: [
                        { id: 5, label: 'New Vehicles' },
                        { id: 3, label: 'Used Vehicles' }
                    ],
                    Home: [
                        { id: 9, label: 'Mortgage' }
                    ],
                    Personal: [
                        { id: 1, label: 'Signature' }
                    ],
                    Recreational: [                
                    ]
                };
                
                //Thiokol
                case '24':
                loanTypes =  {
                    Vehicle: [
                        { id: 27, label: 'Motorcycle - New' },
                        { id: 28, label: 'Motorcycle - Used' },
                        { id: 29, label: 'Auto - New' },
                        { id: 30, label: 'Auto - Used' },
                        { id: 31, label: 'Truck - New' },
                        { id: 32, label: 'Truck - Used' }
                    ],
                    Home: [
                    ],
                    Personal: [
                        { id: 62, label: 'Specials/Signature' },
                        { id: 63, label: 'Spec. Ref. Unsecured' }
                    ],
                    Recreational: [      
                        { id: 25, label: 'R/V - New' },
                        { id: 26, label: 'R/V - Used' },
                        { id: 33, label: 'Boat - New' },
                        { id: 34, label: 'Boat - Used' }
                    ]
                };
 
                //Options
                case '25':
                loanTypes =  {
                    Vehicle: [
                        { id: 1, label: 'Used Auto - Direct' },
                        { id: 3, label: 'New Auto - Direct' }
                    ],
                    Home: [
                    ],
                    Personal: [
                        { id: 5, label: 'Overdraft' },
                        { id: 6, label: 'Unsecured' },
                        { id: 7, label: 'Other' }
                    ],
                    Recreational: [                
                    ]
                };
                
                //Catholic Family - cfcu
                case '26':
                loanTypes =  {
                    Vehicle: [
                    { id: 1, label: 'New Auto' },
                    { id: 2, label: 'Used Auto' },
                    { id: 5, label: 'New Truck' },
                    { id: 14, label: 'Motorcycle' }
                    ],
                    Home: [
                    { id: 15, label: 'HELOC' },
                    { id: 17, label: 'Mobile Home' }
                    ],
                    Personal: [
                    { id: 9, label: 'Signature' },
                    { id: 16, label: 'Indirect Loans' },
                    { id: 20, label: 'Other' }
                    ],
                    Recreational: [       
                    { id: 12, label: 'New Boat' },
                    { id: 13, label: 'Used Boat' },    
                    { id: 18, label: 'Trailer' },
                    { id: 19, label: 'RV' }
                    ]
                };

                //Southernmost - smfcu
                case '27':
                loanTypes =  {
                    Vehicle: [
                    { id: 1, label: 'New Vehicle' },
                    { id: 5, label: 'Used Auto' }
                    ],
                    Home: [
                    ],
                    Personal: [
                    { id: 7, label: 'Signature' },
                    { id: 9, label: 'Debt Consolidation' },
                    { id: 10, label: 'Share Secured' },
                    { id: 70, label: 'Xmas Promo' },
                    { id: 71, label: 'Summer Promo' },
                    { id: 75, label: 'Unsecured Special' },
                    { id: 76, label: 'Back to School' }
                    ],
                    Recreational: [       
                    { id: 6, label: 'RV' },  
                    { id: 8, label: 'Watercraft' }    
                    ]
                };
            }

            return loanTypes;
        },
        //Array of loan types to show in step 1
        loanTypesMain(){
            let ret = [];

            if(this.loanTypesFull['Vehicle'].length > 0){
                ret.push(this.loanTypes['Vehicle']);
            }
            if(this.loanTypesFull['Home'].length > 0){
                ret.push(this.loanTypes['Home']);

            }
            if(this.loanTypesFull['Recreational'].length > 0){
                ret.push(this.loanTypes['Recreational']);

            }
            if(this.loanTypesFull['Personal'].length > 0){
                ret.push(this.loanTypes['Personal']);
            }


            return ret;
        },
        //Coombine all loanTypesFull options into one array for a dropdown select
        loanTypesFullCombined(){
            let ret = [];

            for(var key in this.loanTypesFull){
                for(var ii = 0; ii < this.loanTypesFull[key].length; ii++){
                    ret.push(this.loanTypesFull[key][ii]);
                }
            }

            ret = ret.sort(function(a,b){
                console.log('ret sort: ', a, b);
                if(a.label > b.label)
                    return 1;
                else 
                    return -1;
            })
            ret.unshift({ id: -1, label: 'Select Loan Type'})

            return ret;
        },
        summaryUser(){
            if(this.cosignerShow)
                return this.loanApp.cosigner
            
            return this.loanApp.applicant
        }
      },
        created(){
            document.title = 'Loan Application'
        }, 
      methods:{
        checkClearAccountId(){
            if(this.fv.tempHasAccount.value != 'Yes')
                this.fv.tempAccountId.value = '';
        },
        /* Returns true if val has an '@' before a '.' */
        isValidEmail(val){
            if(val == undefined || val == null)
                return false
            let indexA = val.indexOf('@')
            let indexB = val.indexOf('.')
            if(indexA === -1 || indexB === -1 || indexB < indexA)
                return false
            return true;
        }, 
        isValidPhone(val){
                if(val == undefined || val == null)
                    return false;
                if(isNaN(parseInt(val)) || val.length < 10){
                    return false;
                }
                return true;
        },
        isValidSsn(val){
                if(val == undefined || val == null)
                    return false;
                if(isNaN(parseInt(val)) || val.length < 9){
                    return false;
                }
                return true;
        },
        isApplicantInfoComplete(cosigner){

            let issue = false;
            let checking = this.loanApp.applicant;
            if(cosigner)
                checking = this.loanApp.cosigner;

            //Check name/email
            if(checking.name == undefined || checking.name.length == 0){
                console.log('Invalid name on app data, cosigner: ', cosigner)
                issue = true;
            }
            if(checking.email == undefined || !this.isValidEmail(checking.email)){
                console.log('Invalid email on app data, cosigner: ', cosigner)
                issue = true;
            }

            //Check phone numbers
            if(checking.phoneHome == undefined || !this.isValidPhone(checking.phoneHome)){
                console.log('Invalid phoneHome on app data, cosigner: ', cosigner)
                issue = true;
            }
            
            //Check bday/ssn/citizen status
            if(checking.bday == undefined || checking.bday.length < 8){
                console.log('Invalid bday on app data, cosigner: ', cosigner)
                issue = true;
            }
            if(checking.ssn == undefined || !this.isValidSsn(checking.ssn)){
                console.log('Invalid ssn on app data, cosigner: ', cosigner)
                issue = true;
            }
            if(checking.citizen == undefined){
                console.log('Invalid citizen status on app data, cosigner: ', cosigner)
                issue = true;
            }

            //Check address info
            if(checking.addr1 == undefined || checking.addr1.length == 0){
                console.log('Invalid addr1 on apc data. isCosigner: ', cosigner)
                issue = true;
            }
            if(checking.city == undefined || checking.city.length == 0){
                console.log('Invalid city on apc data. isCosigner: ', cosigner)
                issue = true;
            }
            if(checking.state == undefined || checking.state.length == 0){
                console.log('Invalid state on apc data. isCosigner: ', cosigner)
                issue = true;
            }
            if(checking.zip == undefined || checking.zip.length == 0){
                console.log('Invalid zip on apc data. isCosigner: ', cosigner)
                issue = true;
            }
            if(checking.addrRent == undefined || checking.addrRent.length == 0){
                console.log('Invalid city on apc data. isCosigner: ', cosigner)
                issue = true;
            }
            if(checking.addrSince == undefined || checking.addrSince.length == 0){
                console.log('Invalid addrSince on apc data. isCosigner: ', cosigner)
                issue = true;
            }


            //Check employer info
            if(checking.emp == undefined || checking.emp.length == 0){
                console.log('Invalid emp on apc data. isCosigner: ', cosigner)
                issue = true;
            }
            if(checking.empPos == undefined || checking.empPos.length == 0){
                console.log('Invalid empPos on apc data. isCosigner: ', cosigner)
                issue = true;
            }
            if(checking.empIncome == undefined || checking.empIncome.length == 0){
                console.log('Invalid empIncome on apc data. isCosigner: ', cosigner)
                issue = true;
            }
            if(checking.empSince == undefined || checking.empSince.length == 0){
                console.log('Invalid empSince on apc data. isCosigner: ', cosigner)
                issue = true;
            }

            return !issue;

        },
        /* Returns if all basic loan info is filled in */
        isBasicInfoComplete(){
            let issue = false;

            //Make sure valid loan type
            if(this.loanApp.type == undefined || this.loanTypes[this.loanApp.type] == undefined){
                console.log('Invalid loan type: ', this.loanApp.type);
                issue = true;
            }
            //Make sure loan amount / term / frequency entered
            let parseAmount = parseFloat(this.loanApp.amount)
            if(this.loanApp.amount == undefined || isNaN(parseAmount) || parseAmount <= 0){
                console.log('Invalid loan amount: ', this.loanApp.amount);
                issue = true;
            }
            let parseTerm = parseInt(this.loanApp.term)
            if(this.loanApp.term == undefined || isNaN(parseTerm) || parseTerm <= 0){
                console.log('Invalid loan term: ', this.loanApp.term);
                issue = true;
            }
            if(this.loanApp.freq == undefined || this.loanFreqs[this.loanApp.freq] ==  undefined){
                console.log('Invalid loan freq: ', this.loanApp.freq);
                issue = true;
            }

            return !issue;
        },
        async jumpToStep(step, fieldId){
            this.step = step
            await new Promise(resolve => setTimeout(resolve, 150)) //Slight delay so field ref isn't null
            let field = this.fieldRefs[fieldId]
            console.log('__field: ', JSON.stringify(field))
            if(field != null){
                field.selectField();
            }else{
                console.log('field = null')
            }
        },
        async nextStep(){
            this.alert = '';
            let issue = false;

            //Step 1 complete, check if going to 2 or 3
            if(this.step == 1){
                this.step = 2;
            }
            //Step 2 complete, go to step 3
            else if(this.step == 2){
                this.step = 3;
            }
            //Step 3 complete, make sure loan info values entered
            else if(this.step == 3){
                if(this.fv.tempLoanAmount.value.length == 0){
                    issue = true;
                    this.alert = "Loan amount must be entered"
                }
                else if(this.fv.tempTerm.value.length == 0){
                    issue = true;
                    this.alert = "Term must be entered"
                }
                else if(this.fv.tempFreq.value == ''){
                    issue = true;
                    this.alert = "Frequency must be selected"
                }

                //No issues, copy values to loanApp and go to next step
                if(!issue){
                    this.loanApp.amount = this.fv.tempLoanAmount.value
                    this.loanApp.term = this.fv.tempTerm.value
                    this.loanApp.freq = this.fv.tempFreq.value

                    this.step = 4;
                }
            }
            //Step 4 complete, make sure applicant info values entered, copy from temp to app
            else if(this.step == 4){

                let phoneTrim = this.fv.tempPhoneHome.value.replace('-', '').replace('-', '')
                let ssnTrim = this.fv.tempSsn.value.replace('-', '').replace('-', '')
                
                if(this.fv.tempHasAccount.value.length == 0){
                    issue = true;
                    this.alert = "You must select if you have an account at the Credit Union"
                }
                else if(this.fv.tempHasAccount.value == 'Yes'
                    && this.fv.tempAccountId.value.length == 0){
                    issue = true;
                    this.alert = "You must enter your Credit Union account number"
                }
                else if(this.fv.tempName.value.length == 0){
                    issue = true;
                    this.alert = "Applicant name must be entered"
                }
                else if(!this.isValidEmail(this.fv.tempEmail.value)){
                    issue = true;
                    this.alert = "Applicant email must be entered"
                }
                else if(!this.isValidPhone(phoneTrim)){
                    issue = true;
                    this.alert = "Main phone number must be entered"
                }
                else if(this.fv.tempBday.value.length == 0){
                    issue = true;
                    this.alert = "Applicant birthday must be entered"
                }
                else if(!this.isValidSsn(ssnTrim)){
                    issue = true;
                    this.alert = "Applicant social security number must be entered"
                }
                else if(this.fv.tempCitizen.value == ''){
                    issue = true;
                    this.alert = "Applicant US citizen status must be selected"
                }

                if(!issue){
                    //Copy info to the applicant or cosigner object
                    if(this.cosignerMode){
                        this.loanApp.cosigner['accountId'] = this.fv.tempAccountId.value;
                        this.loanApp.cosigner['name'] = this.fv.tempName.value;
                        this.loanApp.cosigner['hasAccount'] = this.fv.tempHasAccount.value;
                        this.loanApp.cosigner['email'] = this.fv.tempEmail.value;
                        this.loanApp.cosigner['phoneHome'] = this.fv.tempPhoneHome.value.substring(0,12);
                        this.loanApp.cosigner['phoneWork'] = this.fv.tempPhoneWork.value.substring(0,12);
                        this.loanApp.cosigner['bday'] = this.fv.tempBday.value;
                        this.loanApp.cosigner['ssn'] = this.fv.tempSsn.value.substring(0,11);
                        this.loanApp.cosigner['citizen'] = this.fv.tempCitizen.value;
                    }
                    else{
                        this.loanApp.applicant['accountId'] = this.fv.tempAccountId.value;
                        this.loanApp.applicant['name'] = this.fv.tempName.value;
                        this.loanApp.applicant['hasAccount'] = this.fv.tempHasAccount.value;
                        this.loanApp.applicant['email'] = this.fv.tempEmail.value;
                        this.loanApp.applicant['phoneHome'] = this.fv.tempPhoneHome.value.substring(0,12);
                        this.loanApp.applicant['phoneWork'] = this.fv.tempPhoneWork.value.substring(0,12);
                        this.loanApp.applicant['bday'] = this.fv.tempBday.value;
                        this.loanApp.applicant['ssn'] = this.fv.tempSsn.value.substring(0,11);
                        this.loanApp.applicant['citizen'] = this.fv.tempCitizen.value;
                    }

                    this.step = 5;
                }
            }
            //Step 5 complete, make sure applicant address values entered
            else if(this.step == 5){
 
                if(this.fv.tempAddr1.value.length == 0){
                    issue = true;
                    this.alert = "Address Line 1 name must be entered"
                }      
                else if(this.fv.tempCity.value.length == 0){
                    issue = true;
                    this.alert = "City must be entered"
                }  
                else if(this.fv.tempState.value.length != 2){
                    issue = true;
                    this.alert = "State must be entered"
                }
                else if(this.fv.tempZip.value.length != 5){
                    issue = true;
                    this.alert = "5 digit zip must be entered"
                }
                else if(this.fv.tempAddrRent.value.length == 0){
                    issue = true;
                    this.alert = "Mortgage/Rent must be entered"
                }
                else if(this.fv.tempAddrSince.value.length != 10){
                    issue = true;
                    this.alert = "Current Address Since must be entered"
                }

                if(!issue){
                    //Copy info to the applicant object
                    if(this.cosignerMode){
                        this.loanApp.cosigner['addr1'] = this.fv.tempAddr1.value;
                        this.loanApp.cosigner['addr2'] = this.fv.tempAddr2.value;
                        this.loanApp.cosigner['city'] = this.fv.tempCity.value;
                        this.loanApp.cosigner['state'] = this.fv.tempState.value;
                        this.loanApp.cosigner['zip'] = this.fv.tempZip.value;
                        this.loanApp.cosigner['addrRent'] = this.fv.tempAddrRent.value;
                        this.loanApp.cosigner['addrSince'] = this.fv.tempAddrSince.value;
                    }else{
                        this.loanApp.applicant['addr1'] = this.fv.tempAddr1.value;
                        this.loanApp.applicant['addr2'] = this.fv.tempAddr2.value;
                        this.loanApp.applicant['city'] = this.fv.tempCity.value;
                        this.loanApp.applicant['state'] = this.fv.tempState.value;
                        this.loanApp.applicant['zip'] = this.fv.tempZip.value;
                        this.loanApp.applicant['addrRent'] = this.fv.tempAddrRent.value;
                        this.loanApp.applicant['addrSince'] = this.fv.tempAddrSince.value;

                    }

                    this.step = 6;
                }
            }
            //Step 6 complete, make sure applicant financial values entered
            else if(this.step == 6){
    
                if(this.fv.tempEmp.value.length == 0){
                    issue = true;
                    this.alert = "Employer must be entered"
                }      
                else if(this.fv.tempEmpPos.value.length == 0){
                    issue = true;
                    this.alert = "Position must be entered"
                }  
                else if(this.fv.tempEmpIncome.value.length == 0){
                    issue = true;
                    this.alert = "Income must be entered"
                }
                else if(this.fv.tempEmpSince.value.length != 10){
                    issue = true;
                    this.alert = "Date Since must be entered"
                }

                if(!issue){
                    //Copy info to the applicant object
                    if(this.cosignerMode){
                    this.loanApp.cosigner['emp'] = this.fv.tempEmp.value;
                    this.loanApp.cosigner['empPos'] = this.fv.tempEmpPos.value;
                    this.loanApp.cosigner['empIncome'] = this.fv.tempEmpIncome.value;
                    this.loanApp.cosigner['empSince'] = this.fv.tempEmpSince.value;
                    }else{
                    this.loanApp.applicant['emp'] = this.fv.tempEmp.value;
                    this.loanApp.applicant['empPos'] = this.fv.tempEmpPos.value;
                    this.loanApp.applicant['empIncome'] = this.fv.tempEmpIncome.value;
                    this.loanApp.applicant['empSince'] = this.fv.tempEmpSince.value;
                    }

                    //If cosigner mode not selected yet, go to step 7
                    if(this.loanApp.cosignerUsed == undefined)
                        this.step = 7;
                    else
                        this.step = 8;
                }
            }
            //Step 7 complete, selected co-signer           
            else if(this.step == 7){
                if(this.loanApp.cosignerUsed){
                    this.step = 4;
                }else{
                    this.step = 8;
                }
            }
            //Step 8 complete, verify all data and submit loan app       
            else if(this.step == 8){
                
                //Verify basic loan data
                if(!this.isBasicInfoComplete()){
                    issue = true;
                    this.alert = "Basic loan info is not complete"
                    console.log('Basic info not complete')
                }

                //Verify applicant data
                if(!this.isApplicantInfoComplete(false)){
                    issue = true;
                    this.alert = "Applicant info is not complete"
                    console.log('Applicant info not complete')
                }

                //Verify codigner data if enabled
                if(this.loanApp.cosignerUsed && !this.isApplicantInfoComplete(true)){
                    issue = true;
                    this.alert = "Co-Signer info is not complete"
                    console.log('Cosigner info not complete')
                }

                //Verify confirmed info
                if(!this.accept){
                    issue = true;
                    this.alert = "You must confirm that all information is correct"
                    console.log('Info not confirmed')
                }

                if(!issue){
                    console.log('Submit loanApp: ', this.loanApp);
                    //Post the loan app
                    try{
                        await this.$pfapi.post("loanapp/apps", {
                            "data": {
                                "attributes": {
                                    "data": JSON.stringify(this.loanApp)
                                }
                            }
                        })                        
                        this.step = 9;
                    }
                    catch(ex){
                        //set generic error
                        console.log('err: ', ex);
                        /*
                        let errorDetail =  ex.response.data.errors[0].detail;
                        if(errorDetail.indexOf(':') != -1)
                            this.alert = errorDetail.split(':')[1];
                        else
                            this.alert = errorDetail*/
                    }
                }

            }

            //If nextStep hit after already been to review, return to review if no issues
            if(!issue && this.stepHighest == 8 && this.step < 8)
                this.step = 8;

            if(this.step > this.stepHighest)
                this.stepHighest = this.step
        },
        removeCosigner(){
            this.cosignerConfirmRemove = false;
            this.loanApp.cosignerUsed = false;
        },
        saveToEmail(){
            console.log('Save to email.');
            this.saveToEmailStatus = 'saved';
        },
        selectAddCosigner(addCosigner, goToNextStep){
            if(addCosigner){
                this.loanApp.cosignerUsed = true;
                this.showCosigner(true)
            }else{
                this.loanApp.cosignerUsed = false
            }
            if(goToNextStep)
                this.nextStep();
        },
        selectLoanType(type){
            this.loanApp.type = type;        
            this.nextStep();
        },
        selectNewUsed(status){
            this.loanApp.newUsed = status;     
            this.nextStep();
        },
        /* Display applicant or cosigner, copy values to temp inputs */
        showCosigner(show){
            let copyFrom = {};
            if(show){
                copyFrom = this.loanApp.cosigner
                this.cosignerShow = true;
                this.cosignerMode = true;
            }else{
                copyFrom = this.loanApp.applicant
                this.cosignerShow = false;
                this.cosignerMode = false;
            }

            this.fv.tempAccountId.value = (copyFrom.accountId != undefined ? copyFrom.accountId : '')
            this.fv.tempName.value = (copyFrom.name != undefined ? copyFrom.name : '')
            this.fv.tempHasAccount.value = (copyFrom.hasAccount != undefined ? copyFrom.hasAccount : '')
            this.fv.tempEmail.value = (copyFrom.email != undefined ? copyFrom.email : '')
            this.fv.tempPhoneHome.value = (copyFrom.phoneHome != undefined ? copyFrom.phoneHome : '')
            this.fv.tempPhoneWork.value = (copyFrom.phoneWork != undefined ? copyFrom.phoneWork : '')
            this.fv.tempBday.value = (copyFrom.bday != undefined ? copyFrom.bday : '')
            this.fv.tempSsn.value = (copyFrom.ssn != undefined ? copyFrom.ssn : '')
            this.fv.tempCitizen.value = (copyFrom.citizen != undefined ? copyFrom.citizen : '')

            
            this.fv.tempAddr1.value = (copyFrom.addr1 != undefined ? copyFrom.addr1 : '')
            this.fv.tempAddr2.value = (copyFrom.addr2 != undefined ? copyFrom.addr2 : '')
            this.fv.tempCity.value = (copyFrom.city != undefined ? copyFrom.city : '')
            this.fv.tempState.value = (copyFrom.state != undefined ? copyFrom.state : '')
            this.fv.tempZip.value = (copyFrom.zip != undefined ? copyFrom.zip : '')
            this.fv.tempAddrRent.value = (copyFrom.addrRent != undefined ? copyFrom.addrRent : '')
            this.fv.tempAddrSince.value = (copyFrom.addrSince != undefined ? copyFrom.addrSince : '')
            
            this.fv.tempEmp.value = (copyFrom.emp != undefined ? copyFrom.emp : '')
            this.fv.tempEmpPos.value = (copyFrom.empPos != undefined ? copyFrom.empPos : '')
            this.fv.tempEmpIncome.value = (copyFrom.empIncome != undefined ? copyFrom.empIncome : '')
            this.fv.tempEmpSince.value = (copyFrom.empSince != undefined ? copyFrom.empSince : '')

        }
      },
      setup(){

            /* Loan frequency options */
            let loanFreqs = {
                "Weekly": {
                    label: 'Weekly',
                    value: 'Weekly'
                },
                "Bi-Weekly": {
                    label: 'Bi-Weekly',
                    value: 'Bi-Weekly'
                },
                "Monthly": {
                    label: 'Monthly',
                    value: 'Monthly'
                },
                "Semi-Monthly": {
                    label: 'Semi-Monthly',
                    value: 'Semi-Monthly'
                },"Single Payment": {
                    label: 'Single Payment',
                    value: 'Single Payment'
                }
            }

            /* Loan type options */
            let loanTypes = {
                "Home": {
                    icon: 'home',
                    label: 'Home',
                    value: 'Home',
                    allow_used: false
                },
                "Vehicle": {
                    icon: 'truck',
                    label: 'Vehicle',
                    value: 'Vehicle',
                    allow_used: true
                },
                "Personal": {
                    icon: 'user-solid',
                    label: 'Personal',
                    value: 'Personal',
                    allow_used: false
                },
                "Recreational": {
                    icon: 'rocket',
                    label: 'Recreational',
                    value: 'Recreational',
                    allow_used: true
                }
            }
            const accept = ref(false)
            const loanTypeDirect = ref(-1)
            const mobileSummaryExpanded = ref(false)
            const saveToEmailStatus = ref('')

            /* Form field values */
            let fv = {}
            fv['tempFreq'] = ref('')
            fv['tempLoanAmount'] = ref('')
            fv['tempAccountId'] = ref('')
            fv['tempHasAccount'] = ref('')
            fv['tempName'] = ref('')
            fv['tempEmail'] = ref('')
            fv['tempPhoneHome'] = ref('')
            fv['tempPhoneWork'] = ref('')
            fv['tempBday'] = ref('')
            fv['tempSsn'] = ref('')
            fv['tempCitizen'] = ref('No')
            fv['tempAddr1'] = ref('')
            fv['tempAddr2'] = ref('')
            fv['tempState'] = ref('')
            fv['tempZip'] = ref('')
            fv['tempAddrRent'] = ref('')
            fv['tempAddrSince'] = ref('')
            fv['tempCity'] = ref('')
            fv['tempEmp'] = ref('')
            fv['tempEmpPos'] = ref('')
            fv['tempEmpIncome'] = ref('')
            fv['tempEmpSince'] = ref('')
            fv['tempTerm'] = ref('')
            let mon = new Intl.NumberFormat('en-US', {  //mon: Used for money formatting
                style: 'currency',
                currency: 'USD'
            });

            /* Refs to field components */
            const refAddr1 = ref(null)
            const refAddr2 = ref(null)
            const refAddrSince = ref(null)
            const refAmount = ref(null)
            const refBday = ref(null)
            const refCitizen = ref(null)
            const refCity = ref(null)
            const refEmail = ref(null)            
            const refEmp = ref(null)
            const refEmpIncome = ref(null)
            const refEmpPos = ref(null)
            const refEmpSince = ref(null)
            const refFreq = ref(null)
            const refName = ref(null)
            const refPhone = ref(null)
            const refPhoneWork = ref(null)
            const refRent = ref(null)
            const refSsn = ref(null)
            const refState = ref(null)
            const refTerm = ref(null)
            const refZip = ref(null)



            const fieldRefs = ref({
                "addr1": refAddr1,
                "addr2": refAddr2,
                "addrSince": refAddrSince,
                "amount": refAmount,
                "bday": refBday,
                "citizen": refCitizen,
                "city": refCity,
                "email": refEmail,
                "emp": refEmp,
                "empIncome": refEmpIncome,
                "empPos": refEmpPos,
                "empSince": refEmpSince,
                "freq": refFreq,
                "name": refName,
                "phone": refPhone,
                "phoneWork": refPhoneWork,
                "rent": refRent,
                "ssn": refSsn,
                "state": refState,
                "term": refTerm,
                "zip": refZip
            })

            const alert = ref('')
            const cosignerMode = ref(false)
            const cosignerConfirmRemove = ref(false) 
            const cosignerShow = ref(false) //If cosigner info should display on left summary
            const step = ref(1)
            const stepHighest = ref(1) //Highest step progressed to 
            const loanApp = ref({
                type: '',
                applicant: {},
                cosigner: {}
            })
            const sideHeaderClass = ref(' p-1 text-center mn3-content-alt uppercase flex flex-row items-center start')
            const sideItemClass = ref('flex flex-row justify-between px-2 md:pb-0 md:py-1 py-2 rounded-sm cursor-pointer opacity-70 hover:opacity-100 duration-150')
            const stepTitle = ref({
                1: 'Loan Application',
                2: 'Basic Loan Info',
                3: 'Basic Loan Info',
                4: 'Applicant Info',
                5: 'Applicant Address',
                6: 'Applicant Finances',
                7: 'Add Co-Signer',
                8: 'Loan Review',
                9: 'Loan Application Complete'
            })
            const stepToProgress = ref({
                1: 1,
                2: 5,
                3: 10,
                4: 25,
                5: 45,
                6: 60,
                7: 80,
                8: 95,
                9: 100
            })

            function formatMoney(val){
                if(isNaN(parseFloat(val)))
                    return '';
                return mon.format(val);
            }             
            
        return{ 
            accept,
            alert,
            cosignerConfirmRemove,
            cosignerMode,
            cosignerShow,
            fieldRefs,
            formatMoney,
            fv,
            loanApp,
            loanFreqs,
            loanTypeDirect,
            loanTypes,
            mobileSummaryExpanded,
            refAddr1,
            refAddr2,
            refAddrSince,
            refAmount,
            refBday,
            refCitizen,
            refCity,
            refEmail,
            refEmp,
            refEmpIncome,
            refEmpPos,
            refEmpSince,
            refFreq,
            refName,
            refPhone,
            refPhoneWork,
            refRent,
            refSsn,
            refState,
            refTerm,
            refZip,
            saveToEmailStatus,
            stepTitle,
            stepToProgress,
            step,
            stepHighest,
            sideHeaderClass,
            sideItemClass
        }    
      }
    }
</script>
